import axios from 'axios'
import { baseStripeUrl } from '../config'

/**
 * Create a new Stripe checkout session.
 *
 * @param {String} priceId
 * @param {String} patientId
 * @param {String} cusStripeId
 * @param {String} practitionerid
 * @return {Promise<any>}
 */
const createCheckoutSession = (
    priceId,
    patientId,
    cusStripeId,
    practitionerId,
    practitionerAccId,
    period
) => {
    return new Promise((resolve, reject) => {
        const params = {
            priceId,
            patientId,
            cusStripeId,
            practitionerId,
            practitionerAccId,
            period,
        }
        axios
            .post(`${baseStripeUrl}/create-checkout-session`, params)
            .then((res) => resolve(res.data.sessionId))
            .catch((err) => reject(JSON.stringify(err)))
    })
}

export default createCheckoutSession
