import React, { useContext, useEffect, useState } from 'react'

import { Grid, Box, Card, CircularProgress, CardActionArea, CardContent, makeStyles, Typography, Popover } from '@material-ui/core'

import ErrorSnackbar from '../../elements/ErrorSnackBar'
import InfoField from '../../elements/InfoField'
import Notification from '../../elements/Notification'
import UserAvatar from '../../elements/UserAvatar'
import LoadingSpinner from '../../components/utils/LoadingSpinnerCSS'
import IntegrateButton from '../../elements/buttons/IntegrateButton'
import EditButton from '../../elements/buttons/EditButton'

import GoogleFitLogo from '../../assets/google-fit-logo.png'
import FitbitLogo from '../../assets/fitbit-logo.svg'

import PatientsContext from '../../contexts/PatientsContext'
import ChangePasswordDialog from '../Dialog/ChangePasswordDialog'

import { get_googlefit_token } from '../../lib/Api/GoogleFit'
import { get_fitbit_token } from '../../lib/Api/Fitbit'
import { encrypt_basicInfo } from '../../lib/Api/Patient'
import InfoIcon from '@material-ui/icons/Info'


const useStyles = makeStyles((theme) => ({
    googlefitIcon: {
        width: '12rem',
        margin: '3px'
    },
    fitbitLogo: {
        width: '9rem',
        imageRendering: '-webkit-optimize-contrast',
        transform: 'translateZ(0)',
        margin: theme.spacing(8),
    },
    integration: {
        border: 'solid 1px #EEEEEE',
        padding: '16px',
        borderRadius: '4px',
        height: '100%',
    },
    gridContainer: {
        width: '-webkit-fill-available',
        '&.MuiGrid-container': {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
        },
        height: '100%'
    },
    cardContainer: {
        textAlign: 'center',
        width: '250px',
        height: '250px',
    },
    openCardContainer: {
        textAlign: 'center',
        width: '250px',
        height: '250px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        cursor: 'default',
        alignItems: 'center'
    },
    cardActionArea: {
        height: '100%',
        padding: '26px',
        display: 'flex',
        flexDirection: 'column',
    },
    focusHighlight: {
        '&:hover $focusHighlight': {
            opacity: 0,
        },
    },
    googleFitButtons: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    google_popover: {
        width: '270px',


        '& .MuiPopover-paper': {
            padding: '15px'
        }
    },

    margin_left_information: {
        marginLeft: '10px',
        color: 'rgb(0,158,216)',
        cursor: 'pointer',
        zIndex: 1301
    },
    inputButtonIntegrate: {
        padding: '8px 10px',
        fontSize: '0.8125rem',
        color: '#000',
        border: 'none',
        boxShadow: 'none',
        backgroundColor: '#d2d5f7',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#bfc4fd',
        },
        width: '80%'
    },

}))

function GoogleFitIntegration({
    handleSyncGoogleFit
}) {
    const user = useContext(PatientsContext)
    const classes = useStyles()
    const [error, setError] = useState('')
    const [notification, setNotification] = useState('')
    const [googleFitOauthUrl, setGoogleFitOauthUrl] = useState('')
    const [syncingGooglefit, setSyncingGooglefit] = useState(false)
    const [loading, setLoading] = useState(true)


    // Googlefit setup
    const search = window.location.search
    const googlefitParams = new URLSearchParams(search)
    const googlefitStateToken = googlefitParams.get('state')
    const googlefitAuthCode = googlefitParams.get('code')
    let stateToken;

    try {
        const stateParse = JSON.parse(googlefitStateToken);
        stateToken = stateParse?.token;
    } catch (error) {
        console.log("Error parsing googlefitStateToken:", error);
        // Handle the error or fallback logic here
        // For example, you might want to set stateToken to a default value or null
        stateToken = null;
    }


    const initialGoogleFitFunction = async () => {
        setLoading(true)
        const localStateToken = localStorage.getItem('googleFitToken')
        const syncedGoogleFit = localStorage.getItem('syncedGoogleFit')
        // console.log("localStateToken", localStateToken)
        // console.log("syncedGoogleFit", syncedGoogleFit)

        console.log('localStateToken')
        console.log(localStateToken)
        console.log('syncedGoogleFit')
        console.log(syncedGoogleFit)

        console.log("stateToken", stateToken)
        console.log("user.stripeId", user.stripeId)
        console.log("googlefitAuthCode", googlefitAuthCode)
        console.log("syncingGooglefit", syncingGooglefit)


        if (!googlefitStateToken && !user?.data?.connectedDevices?.googleFit) {
            await get_googlefit_token()
                .then((url) => setGoogleFitOauthUrl(url))
                .catch((err) => console.log('get_token::err -', err))
        } else if (
            stateToken === localStateToken &&
            user.stripeId &&
            googlefitAuthCode &&
            !syncedGoogleFit &&
            !syncingGooglefit
        ) {
            setSyncingGooglefit(true)
            handleSyncGoogleFit(user.uid, googlefitAuthCode)
        } else {

            handleSyncGoogleFit(user.uid, googlefitAuthCode)

        }
        setLoading(false)
    }

    useEffect(() => {
        initialGoogleFitFunction()
    }, [
        googlefitStateToken,
        googlefitStateToken,
        googlefitAuthCode,
        user.stripeId,
    ])

    useEffect(() => {
        initialGoogleFitFunction()
    }, [])




    const closeError = () => setError('')

    const closeNotification = () => setNotification('')


    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
        // console.log(event.currentTarget)
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);




    return (
        <>
            <Box className={classes.box}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    className={classes.gridContainer}
                >

                    <Card className={classes.openCardContainer}>
                        {loading || !user.stripeId || syncingGooglefit ?

                            <CircularProgress color="secondary" />
                            : <CardActionArea
                                className={classes.cardActionArea}

                            >
                                <CardContent className={classes.cardContent}>
                                    <>

                                        <img
                                            className={classes.googlefitIcon}
                                            src={GoogleFitLogo}
                                        />
                                        <Typography variant="body2" paragraph>
                                            Sync your GoogleFit data with Floka
                                        </Typography>


                                        <div className={classes.googleFitButtons} >
                                            <input
                                                className={classes.inputButtonIntegrate}
                                                onClick={() => window.location.assign(googleFitOauthUrl)}
                                                type="button"
                                                value={"Sync Once"}
                                            />


                                            <InfoIcon
                                                className={`${classes.margin_left_information}`}
                                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                                aria-haspopup="true"
                                                onMouseEnter={handlePopoverOpen}
                                                onMouseLeave={handlePopoverClose} />

                                            <Popover
                                                id="mouse-over-popover"
                                                sx={{
                                                    pointerEvents: 'none',
                                                }}
                                                open={open}
                                                anchorEl={anchorEl}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                className={classes.google_popover}
                                                onClose={handlePopoverClose}
                                                disableRestoreFocus
                                            >
                                                <Typography
                                                    align="left"
                                                    gutterBottom
                                                    paragraph
                                                    variant="caption"
                                                    style={{ width: '210px' }}
                                                >
                                                    The GoogleFit API does not allow continuous
                                                    background refresh/sync. Please click the sync
                                                    button in Health Data <b>(Health Data {'>'} Sync)</b> to sync
                                                    your account when logged in. If you choose to save
                                                    your GoogleFit password to your browser - this can
                                                    help speed up this for you.
                                                </Typography>
                                            </Popover>




                                        </div>
                                    </>
                                </CardContent>
                            </CardActionArea>}
                    </Card>



                </Grid>



            </Box>

            <ErrorSnackbar error={error} onClose={closeError} />
            <Notification text={notification} onClose={closeNotification} />

        </>
    )
}

export default GoogleFitIntegration
