import React from 'react'
import PropTypes from 'prop-types'
import { Snackbar, Typography, makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.tertiary.main,
    },
    alert: {
        backgroundColor: theme.palette.primary.main,
    },
    text: {
        textAlign: 'center',
    },
}))

function Notification(props) {
    const classes = useStyles()
    const { text, onClose } = props

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={text !== ''}
            onClose={onClose}
            autoHideDuration={3000}
            className={classes.root}
        >
            <Alert variant="filled" className={classes.alert} onClose={onClose}>
                <Typography variant="body1" className={classes.text}>
                    <b>{text}</b>
                </Typography>
            </Alert>
        </Snackbar>
    )
}

Notification.propTypes = {
    text: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default Notification
