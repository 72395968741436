import { projectAuth, database } from './firebase_config'
import { Mixpanel } from '../Analytics/Mixpanel'
import { toDateString } from '../../helpers/dateHelpers'

/**
 * Signin current login patient
 */
const signin = (email, pass) => {
    return new Promise((resolve, reject) => {
        projectAuth
            .signInWithEmailAndPassword(email, pass)
            .then((res) => {
                const { user } = res
                if(!user.emailVerified){
                    throw new Error('Please verify your account, check your email.')
                }
                const today = new Date()
                const formatedTime = toDateString(today)
                Mixpanel.identify(user.uid)
                Mixpanel.track('Successful Login', {
                    'Login At': formatedTime,
                })
                Mixpanel.people.set({
                    $email: user.email,
                })
                resolve(user)
            })
            .catch(function (error) {
                reject(error.message)
            })
    })
}

export default signin
