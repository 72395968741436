
import axios from 'axios';
import { baseUrl } from '../Api/config';
const { projectAuth } = require('../Auth/firebase_config')

/**
 * Update a flow entry with @param key through backend routes.
 *
 * @param {Object} params - has cycle date
 * @param {String} patientId
 * @return {Promise} - response data
 */

const _deleteCycleEntry =(params, patientId)=>{

    return new Promise((resolve, reject)=>{
       projectAuth.currentUser
          .getIdToken(true)
          .then((idToken)=>{
            const reqBody = {
                headers: {
                    Authorization: `${idToken}`,
                },
            };
                axios.post(`${baseUrl}/patient/${patientId}/cycle/delete`, params, reqBody)
                    .then(res =>{
                        resolve(res);
                    })
                    .catch((err)=>{ 
                        console.log('delete_cycle_entry::err:: -', err)
                    })

          })
          .catch((error)=>{
            reject(error.message || error)
        })
    })

}

export default _deleteCycleEntry