import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Get a list of schedule items from Program-Schedules table in dynamoDB.
 *
 * @return {Promise<any>}
 */
const getSchedules = (programId) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
        .getIdToken(true)
        .then(function (idToken) {
                console.log('idToken',idToken)
                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                }

                axios
                    .get(`${baseUrl}/patient/schedules/${programId}`, reqBody)
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((error) => reject(error))
    })
}

export default getSchedules
