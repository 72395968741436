

import axios from 'axios';
import { baseUrl } from '../Api/config';
const { projectAuth } = require('../Auth/firebase_config')

const updateBodyData = (params, patientId) =>{

    return new Promise((resolve, reject)=>{
        projectAuth.currentUser
           .getIdToken(true)
           .then(function(idToken){
             const reqBody = {
                headers: {
                     Authorization: `${idToken}`,
                },
            };
                axios.post(`${baseUrl}/patient/${patientId}/body/update`, params, reqBody)
                     .then(res =>{
                         resolve(res.data);
                     })
                     .catch((err)=>{ 
                         console.log('update_body_entry::err:: -', err)
                     })
 
           })
           .catch((error)=>{
             reject(error.message || error)
         })
     })
}

export default updateBodyData