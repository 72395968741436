import decrypt from './decrypt'

/**
 * Calculate the age of patient from the hashed birthDate string
 *
 * @param {String} birthDate Hash string from Firebase
 * @returns {Number} Year difference between decrypted birthDate and now
 */
function getAge(birthDate) {
    const decryptedDate = decrypt(birthDate)
    let today = new Date()
    let birthDay = new Date(decryptedDate)
    let age = new Date(today - birthDay).getFullYear() - 1970
    if (age < 18) return 18
    return age
}

export default getAge
