import React from 'react'
import useEditButton from '../../hooks/useEditButton'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    IconButton,
    Typography,
    Grid,
    makeStyles,
} from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import ErrorSnackbar from '../ErrorSnackBar'

const useStyles = makeStyles((theme) => ({
    field: {
        border: 'solid 1px #EEEEEE',
        padding: theme.spacing(0, 4),
        borderRadius: '4px',
    },
}))

function EditButton(props) {
    const classes = useStyles()
    const { fieldName } = props
    const {
        state,
        handleOpen,
        handleFirstNameChange,
        handleCancel,
        handleSave,
        handleKeyUp,
        handleErrorClose,
    } = useEditButton(props)
    const { error, firstName, open } = state

    let component = (
        <TextField
            autoFocus
            margin="dense"
            id="first-name"
            label="First Name"
            name="firstName"
            fullWidth
            variant="outlined"
            onChange={handleFirstNameChange}
            onKeyUp={handleKeyUp}
            value={firstName}
            error={firstName === ''}
            helperText={firstName === '' ? 'Required field' : ''}
        />
    )

    return (
        <React.Fragment>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.field}
            >
                <Typography variant="body1">{props.firstName}</Typography>
                <IconButton aria-label="Edit" onClick={handleOpen}>
                    <EditOutlinedIcon fontSize="small" />
                </IconButton>
            </Grid>
            <Dialog fullWidth open={open} aria-labelledby={fieldName}>
                <DialogTitle id={fieldName}>Edit {fieldName}</DialogTitle>
                <DialogContent>{component}</DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <ErrorSnackbar error={error} onClose={handleErrorClose} />
        </React.Fragment>
    )
}

export default EditButton
