import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Get patient's nutrition data from AWS DynamoDB.
 *
 * @params {String} patientId
 * @return {Promise<any>}
 */
const getClinicFiles = (patientId) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                }

                axios.get(
                    `${baseUrl}/patient/${patientId}/clinicResources`,
                    reqBody
                )
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export default getClinicFiles