import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Update recommendation list for patient with @param patientId 's profile.
 *
 * @param {String} patientId Patient's Firebase id
 * @param {String} practitionerId Practitioner's Firebase id
 * @param {Number} index Index of recommendation to be updated from the recommendations list
 * @param {Object} newItem recommendation item
 * @param {string} label recommendation
 * @return {Promise<any>}
 */
const updateRecommendation = (
    patientId,
    practitionerId,
    index,
    newItem,
    label
) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                const params = { index, newItem, label }
                const reqBody = {
                    headers: { Authorization: `${idToken}` },
                    data: { label },
                }
                axios
                    .put(
                        `${baseUrl}/patient/${patientId}/recommendations/${practitionerId}/update`,
                        params,
                        reqBody
                    )
                    .then((res) => resolve(res.data.recommendation[0]))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export default updateRecommendation
