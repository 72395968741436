import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Update patient information in firebase database with @param patientId
 *
 * @param {String} value
 * @param {String} attr - attribute name
 * @param {String} patientId - Patient's Firebase ids
 * @return {Promise<any>}
 */
const encryptBasicInfo = (value, attr, patientId) => {
    return new Promise(async (resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                const reqBody = { headers: { Authorization: `${idToken}` } }
                const params = { value, attr, patientId }
                axios
                    .post(`${baseUrl}/adminPatient/encrypt`, params, reqBody)
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((error) => reject(error))
    })
}

export default encryptBasicInfo
