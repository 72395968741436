import { database } from '../../Auth/firebase_config'

/**
 * Update patient's basic information with @param value and @param attr
 * in Firebase.
 *
 * @params {String} value
 * @params {String} attr
 * @params {String} patientId
 * @return {Promise<any>}
 */
const updateBasicInfo = (value, attr, patientId) => {
    return new Promise((resolve, reject) => {
        const basicInfoRef = database.ref(`/users/${patientId}/basicInfo`)
        basicInfoRef.child(attr).set(value)
        basicInfoRef.on('value', (snapshot) => resolve(snapshot.val()))
    })
}

export default updateBasicInfo
