import axios from 'axios'
import { Mixpanel } from '../Analytics/Mixpanel'
import { encrypt_basicInfo } from '../Api/Patient'
import { projectAuth, googleProvider, database } from './firebase_config'

/**
 * Signin patient using Google.
 */
const signinGoogle = () => {
    return new Promise((resolve, reject) => {
        projectAuth
            .signInWithPopup(googleProvider)
            .then(async (result) => {
                const userId = result.user.uid

                try {
                    const patientRef = database.ref(`users/${userId}`)
                    patientRef.on(
                        'value',
                        (snapshot) => {
                            const data = snapshot.val()
                            const basicInfo = data?.basicInfo

                            if (!basicInfo) {
                                const credential = result.credential.accessToken
                                const accountId =
                                    result.additionalUserInfo.profile.id

                                const url = `https://people.googleapis.com/v1/people/${accountId}?personFields=birthdays&access_token=${credential}`
                                const header = {
                                    Accept: 'application/json',
                                    'Content-Type':
                                        'application/json;charset=UTF-8',
                                }

                                axios
                                    .get(url, header)
                                    .then((res) => {
                                        let birth =  "Not available" 
                                        if (res.data.birthdays && res.data.birthdays[0].date) {
                                            const date = res.data.birthdays[0].date
                                            birth = date.month + '-' + date.day + '-' + date.year
                                        }
                                        const name =
                                            result.additionalUserInfo.profile
                                                .given_name
                                        let basicInfo = []
                                        basicInfo.push({ name })
                                        basicInfo.push({ birth })

                                        const promises = basicInfo.map(
                                            (info) => {
                                                const attr =
                                                    Object.keys(info)[0]
                                                const val =
                                                    Object.values(info)[0]
                                                return encrypt_basicInfo(
                                                    val,
                                                    attr,
                                                    userId
                                                )
                                            }
                                        )

                                        Promise.all(promises)
                                            .then(([...data]) => {
                                                Mixpanel.track(
                                                    'Update basic information'
                                                )
                                                localStorage.setItem(
                                                    'isFirstLogin',
                                                    false
                                                )
                                                resolve({ name })
                                            })
                                            .catch((err) =>
                                                console.log(
                                                    'saveBasicInfo::encrypt_basicInfo::error - ',
                                                    err
                                                )
                                            )
                                    })
                                    .catch((error) => reject(error.message))
                            } else {
                                resolve('success')
                            }
                        },
                        function (err) {
                            console.log('basicInfoRef::err -', err)
                        }
                    )
                } catch (err) {
                    reject(`getProfile::error - ${JSON.stringify(err)}`)
                }
            })
            .catch((error) => reject(error.message))
    })
}

export default signinGoogle
