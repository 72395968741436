import axios from 'axios'
import { projectAuth, database } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Get a list of program items from Program-Patient table in dynamoDB.
 *
 * @return {Promise<any>}
 */
const getPrograms = (patientId, practitionerId) => {
    return new Promise((resolve, reject) => {
   
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                // console.log('idToken', idToken)
                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                }

                axios
                    .get(
                        `${baseUrl}/adminPatient/programs/${patientId}/practitioner/${practitionerId}`,
                        reqBody
                    )
                    .then((res) => {
                        console.log('aXIOS SUCCESSS', res)
                        const programs = res.data.filter(
                            (pro) => pro.type === 'x'
                        )
                        const programIds = programs.map(
                            (el) => el.program_id
                        )
                        if (programs && programs.length) {
                            resolve(programIds)
                        } else {
                            resolve([])
                        }
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch(function (error) {
                reject(error)
            })
            
    })
}

export default getPrograms
