import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Change  "Viewed" flag on each user's notification
 *
 * @return {Promise<any>}
 */
const updateNotificationViewed = (patId) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
        .getIdToken(true)
           .then((idToken) =>{ 
                const reqBody = { headers: { Authorization: `${idToken}` } }
                const params = {}
                axios
                    .post(`${baseUrl}/patient/viewedNotifications/${patId}`, params, reqBody)
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default updateNotificationViewed