import CryptoJS from 'crypto-js'

/**
 * Convert hex string into byte array
 *
 * @param {String} str hex string
 * @return {Uint8Array} Array contains bytes for hex string
 */
function _hexStringToByte(str) {
    if (!str) {
        return new Uint8Array()
    }

    let a = []
    for (let i = 0, len = str.length; i < len; i += 2) {
        a.push(parseInt(str.substr(i, 2), 16))
    }

    return new Uint8Array(a)
}

/**
 * Decrypt hashed string retrieved from Firebase
 * Use: first name and age decryption in patient profile
 *
 * @param {String} message hash string
 * @return {String} Decrypted string
 */
function decryptPatient(message = '') {
    let blockSize = 16
    let key = process.env.REACT_APP_DECRYPTION_KEY

    let encryptedBytes = _hexStringToByte(message)
    let messageSize = encryptedBytes.length

    if (messageSize <= blockSize) return null

    let iv = encryptedBytes.slice(0, blockSize)

    let encryptedMessageBytes = encryptedBytes.slice(-(messageSize - blockSize))

    const decrypted = CryptoJS.AES.decrypt(
        {
            ciphertext: CryptoJS.enc.Hex.parse(
                Buffer.from(encryptedMessageBytes).toString('hex')
            ),
        },
        CryptoJS.enc.Utf8.parse(key),
        { iv: CryptoJS.enc.Hex.parse(Buffer.from(iv).toString('hex')) }
    )
    // console.log("Decrypted:", decrypted.toString(CryptoJS.enc.Utf8));

    return decrypted.toString(CryptoJS.enc.Utf8)
}

export default decryptPatient
