import React, { useEffect, useState } from 'react'
import { INTEGRATIONSPAGE } from '../constants/routes'
import { Link as RouterLink, Route, useLocation } from 'react-router-dom'

import TempDropIntegration from '../components/Account/TempDropIntegration'
import OuraIntegrationCard from '../components/Account/OuraIntegrationCard'
import { Grid, Typography, Tabs, Box, Tab, makeStyles } from '@material-ui/core'
import TabPanel from '../elements/tabs/TabPanel'
import FitbitIntegration from '../components/Account/FitbitIntegration'
import GoogleFitIntegration from '../components/Account/GoogleFitIntegration'
import MyFitnessPalIntegrationVital from '../components/Account/MyFitnessPalCard_Vital'
import FreestyleCard from '../components/Account/FreestyleCard'
import WhoopsCard from '../components/Account/WhoopsCard'

import { Mixpanel } from '../lib/Analytics/Mixpanel'


const useStyles = makeStyles((theme) => ({
    alignment: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '20px'
    }
}))


const Integrations = ({
    handleSyncGoogleFit,
    handleSyncFitbit,
    handleRevokeFitbit,
    user
}) => {
    const classes = useStyles()


    const accountRoutes = [INTEGRATIONSPAGE]
    const tabLabels = ['Integrations']

    let location = useLocation()
    const [value, setValue] = useState(accountRoutes.indexOf(location.pathname))


    useEffect(() => {
        if (location.pathname === INTEGRATIONSPAGE) setValue(0)
    }, [location.pathname])

    const handleChange = (event, newValue) => {
        setValue(newValue)
        Mixpanel.track(`View ${tabLabels[newValue]}`)
    }


    return (


        <Box>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
            >
                {accountRoutes.map((route, i) => (
                    <Tab
                        key={i}
                        component={RouterLink}
                        to={route}
                        label={tabLabels[i]}
                        index={i}
                    />
                ))}
            </Tabs>

            <Route
                exact
                path={INTEGRATIONSPAGE}
                render={() => (
                    <TabPanel
                        value={value}
                        index={accountRoutes.indexOf(INTEGRATIONSPAGE)}
                    >

                        <div className={classes.alignment} >

                            <TempDropIntegration user={user} />
                            
                            <OuraIntegrationCard user={user} />


                            <FitbitIntegration
                                handleSyncGoogleFit={handleSyncGoogleFit}
                                handleSyncFitbit={handleSyncFitbit}
                                handleRevokeFitbit={handleRevokeFitbit}
                            />

                            <GoogleFitIntegration
                                handleSyncGoogleFit={handleSyncGoogleFit}

                            />
                            
                            <MyFitnessPalIntegrationVital 
                                user={user}
                            />
                            
                            <FreestyleCard 
                                user={user}
                            />
                            
                            <WhoopsCard 
                                user={user}
                            />
                        </div>



                    </TabPanel>
                )}
            />



        </Box>
    )
}

export default Integrations