/**
 * extract file or image infor formData
 *
 * @param {String} courseData parsed data to send to the backend
 */

const FileDataExtractionIntakeForm = (fileData) =>{

    return new Promise(async (resolve, reject) => {

    
        const file = new FormData()
        file.append('intakeForm',fileData);
        file.set('fileData', JSON.stringify(fileData))

        resolve({
            fileData: file
        })
    })

}

export default FileDataExtractionIntakeForm