import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'


export const getOuraData = (user) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                // console.log(idToken)
                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                }
                const params = {
                    patientID: user
                }
                axios
                    .post(`${baseUrl}/oura/user_data`, params, reqBody)
                    .then((res) => resolve(res.data))
                    .catch((err) => {
                        reject('Error getting the data, please try again!')
                        window.location.href = "/account/oura"
                    })
            })
            .catch(function (error) {
                reject(error)
            })
    })
}
