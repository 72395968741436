import axios from 'axios'
import { projectAuth, database } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Accept an invitation items for a patient with @param patientId.
 * Adds a practitioner firebase branch with the practitioner's ID equal to true.
 *
 * @param {String} patientId patient ID
 * @param {Object} invite
 * @return {Promise<any>}
 */
const sendInvitationToCoach = (patientId, name, patientEmail, practitionerEmail, type, timeSent, clientName, invitationId, coachUnique) => {
    // console.log('accept invite', invite);
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                }
                const params = { patientId, name, patientEmail, practitionerEmail, type, timeSent, clientName, id: invitationId, coachUnique }

                axios
                    .post(
                        `${baseUrl}/adminPatient/invitation/resend-to-coach`,
                        params,
                        reqBody
                    )
                    .then((res) => {
                        resolve(res.data)
                    })
                    .catch((err) => {
                       reject(err)
                        
                    })
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export default sendInvitationToCoach
