import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'
import { populateIndex } from '../../../helpers/generalHelpers'

/**
 * Add index to data.
 *
 * @param {Object[]} data recommendation and notes
 * @return {Object[]} result
 */
function populateData(data) {
    let result = []
    if (!data) return result
    result = populateIndex(data)
    return result
}

/**
 * Get patient's firebase data, profile image, subscription information and insights data.
 *
 * @return {Promise<any>}
 */
const getRecItems = (patientId) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                }

                axios
                    .get(
                        `${baseUrl}/patient/${patientId}/files`, 
                        reqBody
                    )
                    .then((res) => {
                        console.log('res', res)
                        const data = {
                            notes: populateData(res.data.notes),
                            recommendations: populateData(
                                res.data.recommendation
                            ),
                            files: populateData(res.data.files || [])
                        }
                        resolve(data)
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export default getRecItems