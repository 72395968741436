import axios from 'axios'
import { projectAuth, database } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Get a list of synced schedules items from Program-Patient table in dynamoDB.
 *
 * @return {Promise<any>}
 */
const getSyncedSchedules = (patientId) => {
    return new Promise((resolve, reject) => {
        const practitionerRef = database.ref(`/users/${patientId}/practitioner`)

        practitionerRef.on(
            'value',
            (snapshot) => {
                const data = snapshot.val()
                if (!data) return
                const pracId = Object.keys(data)

                projectAuth.currentUser
                    .getIdToken(true)
                    .then(function (idToken) {
                        const reqBody = {
                            headers: {
                                Authorization: `${idToken}`,
                            },
                        }

                        axios
                            .get(
                                `${baseUrl}/adminPatient/syncedPrograms/${patientId}/practitioner/${pracId[0]}`,
                                reqBody
                            )
                            .then((res) => {
                                resolve(res.data[0].schedules)}
                                )
                            .catch((err) => reject(JSON.stringify(err)))
                    })
                    .catch(function (error) {
                        reject(error)
                    })
            },
            function (err) {
                console.log('practitionerRef::error -', err)
            }
        )
    })
}

export default getSyncedSchedules
