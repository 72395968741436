import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Generate a random string with @param length.
 *
 * @param {Number} length
 * @returns {String} result
 */
function makeid(length) {
    var result = ''
    var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        )
    }
    return result
}

/**
 * Retrieves Google Fit API oAuth url.
 *
 * @param {String} patientId patient ID
 * @param {Object} invite
 * @return {Promise<any>}
 */
const getGooglefitToken = () => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                const stateToken = makeid(30)
                localStorage.setItem('googleFitToken', stateToken)

                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                }
                const params = {
                    token: stateToken,
                }

                axios
                    .post(`${baseUrl}/googleFit/oAuth`, params, reqBody)
                    .then((res) =>{ 
                        resolve(res.data.url)
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export default getGooglefitToken
