import React, { useContext, useEffect, useState } from 'react'

import { Grid, Typography, makeStyles, Box, Divider } from '@material-ui/core'

import ErrorSnackbar from '../../elements/ErrorSnackBar'
import InfoField from '../../elements/InfoField'
import Notification from '../../elements/Notification'
import UserAvatar from '../../elements/UserAvatar'
import LoadingSpinner from '../../components/utils/LoadingSpinnerCSS'


import { exchangeToken } from '../../lib/Api/MyFitnessPal/exchangeToken'
import { getMyFitnessPalData } from '../../lib/Api/MyFitnessPal/getMyFitnessPalData'
import { useHistory } from 'react-router-dom'




const MyFitnessIntegration = ({ user, refreshAppData }) => {

    let history = useHistory()

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(true)
    const [notification, setNotification] = useState('')
    const [firstTime, setFirstTime] = useState(true)

    const closeError = () => setError('')
    const closeNotification = () => setNotification('')

    useEffect(() => {
        console.log('INITIALLLLLL', user)
        if(firstTime){
            setFirstTime(false)
            initialFunction()
        }
        
    }, [null])

    const initialFunction = async () => {

        const queryParams = new URLSearchParams(window.location.search)
        const code = queryParams.get("code")
        const error = queryParams.get("error")

        setLoading(true)
        try {

            // if (code) {

            //     //New user is giving access to the data
            //     console.log('exchange token')
            //     await exchangeToken(code, user.uid)
            //     await getMyFitnessPalData(user.uid)
            //     setNotification(
            //         'MFP Integration succesfully!'
            //     )
            //     refreshAppData()


            // }
            // else if (error) {
            //     //If an error comes up
            //     // const error_description = queryParams.get("error_description")
            //     // throw error_description
            // }
            // else {
            //     //This is an user with storedToken and authorization to access data.
            //     // Load the tempdrop data 


            //     history.push('/integrations')


            // }

            setLoading(false)
        } catch (err) {
            //Handle errors

            // setError(
            //     err
            // )

            setTimeout(() => {
                history.push('/integrations')

            }, 3000)
        }

    }



    return (<>

        <LoadingSpinner text="Loading data..." />
        <ErrorSnackbar error={error} onClose={closeError} />
        <Notification text={notification} onClose={closeNotification} />
    </>)
}

export default MyFitnessIntegration