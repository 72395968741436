import React from 'react'
// import PropTypes from 'prop-types';
import clsx from 'clsx'
import { createStyles, lighten, makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import WarningIcon from '@material-ui/icons/Warning'
import SyncIcon from '@material-ui/icons/Sync'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'

const useStyles = makeStyles((theme) =>
    createStyles({
        complete: {
            backgroundColor: lighten('#00b072', 0.3),
        },
        incomplete: {
            backgroundColor: theme.palette.primary.main,
        },
        field: {
            border: 'solid 1px #EEEEEE',
            padding: theme.spacing(2, 4),
            borderRadius: '4px',
        },
        chip: {
            margin: '3px',
            color:"#000",
            padding:"5px",
            whiteSpace:'initial',
            height:"fit-content"
            
        },
        longChip: {
            // border:"1px solid red",
            padding:"5px",
            whiteSpace: 'initial',
            overflow: 'hidden',
            // height:"fit-content",
            maxWidth:"400px",
        },
        container:{
            display:'flex',
            flexWrap:"wrap",
            whiteSpace:"initial",
            height:"fit-content"
        }
    })
)

function InfoField(props) {
    const classes = useStyles()
    const { xs, value, name, type } = props

    if (Array.isArray(value) && value.length > 0)
        return (
            <Grid item xs={xs} className={classes.container}>
                {value.map((v, i) => (
                    v.length>50?
                    <Chip 
                        color="secondary" 
                        className={classes.chip} 
                        key={i} 
                        label={(
                            <section>
                              <div className={classes.longChip}> {v}</div>
                            </section>
                          )}
                    />
                        :
                    <Chip color="secondary" className={classes.chip} key={i} label={v} />

                ))}
            </Grid>
        )

    function getIcon() {
        if (typeof value === 'boolean') {
            if (value) return <CheckCircleOutlineIcon />
            if (value === null) return <SyncIcon />
            return <WarningIcon />
        } else {
            return <AssignmentLateIcon />
        }
    }

    if (type) {
        return (
            <Chip
                label={name}
                icon={getIcon()}
                className={clsx({
                    [classes.complete]: value,
                    [classes.incomplete]: value === null,
                })}
            />
        )
    }

    return (
        <Grid item xs={xs} className={classes.field}>
            {value && value !== '(   )    -    ' && value.length > 0 ? (
                value.split('\n').map((text, i) => (
                    <Typography variant="body1" key={i}>
                        {text}
                    </Typography>
                ))
            ) : (
                <Typography variant="body1">
                    <i>Add {name}</i>
                </Typography>
            )}
        </Grid>
    )
}

InfoField.propTypes = {
    // xs: PropTypes.number.isRequired,
    // value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
    // name: PropTypes.string.isRequired
}

export default InfoField
