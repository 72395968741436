

import axios from 'axios';
import { baseUrl } from '../Api/config';
const { projectAuth } = require('../Auth/firebase_config')

const getBranchData = (patientId, branch) => {

    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                };
                axios.get(`${baseUrl}/patient/${patientId}/data/${branch}`, reqBody)
                    .then(res => {
                        console.log(res.data)
                        resolve(res.data);
                    })
                    .catch((err) => {
                        console.log('get_branch_data:err:: -', err)
                    })

            })
            .catch((error) => {
                reject(error.message || error)
            })
    })
}

export default getBranchData