export const ACCOUNT = '/account'
export const ACCOUNT_SETTING ='/account/setting'
export const COURSE_HISTORY = '/account/course'
export const INTEGRATIONS = '/account/integrations'
export const TEMPDROP = '/account/tempdrop'
export const OURA = '/account/oura'
export const MY_FITNESS_PAL='/account/myfitnessPal'
export const FILES = '/files'
export const LAB_REPORT = '/files/lab-report'
export const INTAKE_FORM = '/intake-form'
// export const PLANS = '/account/plans';
// export const BUSINESS = '/business';
export const CHAT = '/chat'
// export const INVITATIONS = '/invitations';
// export const INV_HISTORY = '/invitations/history';
export const LANDING = '/'
export const HISTORY = '/history'
export const HEALTH_HISTORY = '/history/health'
export const NOTIFICATION = '/notification'
export const PASSWORD_FORGET = '/pw-forget'
export const PAST_PROGRAMS = '/programs/past'
export const PROGRAMS = '/programs'
export const WORKOUT = '/programs/:programId/:scheduleDay/:scheduleWeek'
export const RECOMMENDATIONS = '/recommendations'
export const INTEGRATIONSPAGE = '/integrations'

// export const PATIENT_PROFILE = '/patients/:id';
// export const PILLAR_DETAILED_VIEW = '/patients/:id/detail';
// export const REQUESTS = '/requests';
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const SUBSCRIPTIONS = '/subscriptions'
export const SUBSCRIPTIONS_HISTORY = '/subscriptions/history'
// export const SALES = '/business/sales';
// export const TAX_CONFIG = '/business/tax-config';

export const MY_COURSES = '/courses'
export const DISCOVER_COURSES = '/courses/all/discover'
export const PAST_COURSES = '/courses/all/past'
export const COURSE_OVERVIEW = '/courses/:id'
export const LESSON = '/courses/:id/:moduleIndex/:lessonId'

export const USER_NOTIFICATION = '/user/notification'
export const USER_NOTIFICATION_SETTING = '/user/notification/setting'