import React, {
    useState
} from 'react'
import { Button, Grid, Icon, IconButton, makeStyles, Typography, Popover } from '@material-ui/core'
import InfoField from '../InfoField'
import FitbitIcon from '../../assets/fitbit-app-icon-square.png'
import GoogleNormalBlueButton from '../../assets/google-normal-blue-button.png'
import InfoIcon from '@material-ui/icons/Info'
import CachedIcon from '@mui/icons-material/Cached'




const useStyles = makeStyles((theme) => ({
    buttonConnect: {
        borderColor: '#00B0B9',
        color: '#00B0B9',
        '&.MuiButton-root': {
            lineHeight: 'unset',
        },
    },
    fitbitIcon: {
        width: '100%',
        height: '100%',
    },
    signinGoogle: {
        paddingTop: '0',
    },
    inputButtonIntegrate: {
        padding: '8px 10px',
        fontSize: '0.8125rem',
        color: '#000',
        border: 'none',
        boxShadow: 'none',
        backgroundColor: '#d2d5f7',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#bfc4fd',
        },
        width: '80%'
    },
    inputButtonAccess: {
        width: '80%',
        padding: '8px 10px',
        fontSize: '0.8125rem',
        color: 'white',
        border: 'none',
        boxShadow: 'none',
        backgroundColor: '#ff6666',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#ff3232',
        },
    },
    options: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    googleFitButtons: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },

    margin_left_information: {
        marginLeft: '10px',
        color: 'rgb(0,158,216)',
        cursor: 'pointer',
        zIndex: 1301
    },

    google_popover: {
        width: '270px',
        // zIndex:1,
        marginLeft: "30px",
        '& .MuiPopover-paper': {
            padding: '15px'
        }
    },
    spinRefresh: {
        animation: "$spin 3s linear infinite"

    },
    "@keyframes spin": {
        "0%": { transform: "rotate(360deg)" },
        "100%": { transform: "rotate(0deg)" }
    },
    fitbitDisconnect: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        gap: "15px",
    }
}))



const SigninButton = (label, link, styles) => {


    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
        // console.log(event.currentTarget)
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);


    if (label === 'fitbit') {
        return (
            <input
                className={styles.inputButtonIntegrate}
                onClick={() => window.location.assign(link)}
                type="button"
                value={"Integrate Now"}
            />
        )
    } else {
        return (

            <div className={styles.googleFitButtons} >
                <input
                    className={styles.inputButtonIntegrate}
                    onClick={() => window.location.assign(link)}
                    type="button"
                    value={"Sync Once"}
                />


                <InfoIcon
                    className={`${styles.margin_left_information}`}
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose} />

                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    className={styles.google_popover}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Typography
                        align="left"
                        gutterBottom
                        paragraph
                        variant="caption"
                        style={{ width: '210px' }}
                    >
                        The GoogleFit API does not allow continuous
                        background refresh/sync. Please click the sync
                        button in Health Data <b>(Health Data {'>'} Sync)</b> to sync
                        your account when logged in. If you choose to save
                        your GoogleFit password to your browser - this can
                        help speed up this for you.
                    </Typography>
                </Popover>




            </div>

        )
    }
}

const IntegrateButton = ({ type, isSyncing, user, url, onRevoke, updateFitbitData, updatingDataLoadeer }) => {




    const classes = useStyles()
    const isConnected =
        type === 'fitbit'
            ? user?.data?.connectedDevices?.fitbit &&
            user?.data?.basicInfo?.fbToken
            : user?.data?.connectedDevices?.googlefit &&
            localStorage.getItem('syncedGooglefit')

    if (type === 'fitbit' && user?.data?.fitbitToken?.refresh_token) {
        return (

            <div className={classes.fitbitDisconnect}>


                <input
                    className={classes.inputButtonAccess}
                    type="button"
                    value={"Disconnect"}
                    onClick={() => onRevoke(user?.data?.basicInfo?.fbToken, user?.uid)}
                />

                <CachedIcon className={updatingDataLoadeer ? classes.spinRefresh : null} onClick={() => updateFitbitData()} />

            </div>

        )
    }
    return isSyncing ? (
        <InfoField value={null} type={type} name="syncing..." />
    ) : user?.data?.fitbitToken?.refresh_token ? (
        <InfoField value={true} type={type} />
    ) : user.stripeId && url ? (
        SigninButton(type, url, classes)
    ) : (
        ''
    )
}

export default IntegrateButton
