import { projectAuth, database } from './firebase_config'
import { encrypt_basicInfo } from '../../lib/Api/Patient'

const redirect_url = process.env.REACT_APP_NODE_ENV === 'production' ? 'https://myprogram.portal.floka.co/signin?verification=true' : 'https://d3bwvrv42ipfnh.cloudfront.net/signin?verification=true'

/**
 * Sign up patient in Firebase Authentication with @param credentials and send a vertifcation email.
 * @param {Object} credentials - Patient signup credentials
 * @return {Promise}
 */
const signup = (cred,isNewsletterSubscribed) => {

    return new Promise((resolve, reject) => {
        projectAuth
            .createUserWithEmailAndPassword(cred.email, cred.password)
            .then((res) => {
                // adding user's time zone into firebase when sign up
                let tz = new Date().toString().split(' ')[5];
                tz = tz.substring(0, 6) + ":" + tz.substring(6, tz.length);
                tz = tz.replace('GMT', 'UTC')

                const location= Intl.DateTimeFormat().resolvedOptions().timeZone

                const patientRef = database.ref(
                    `/users/${res.user.uid}`
                 )
                 patientRef.set({
                    isNewsletterSubscribed: isNewsletterSubscribed,
                    timeZone: `${location} ${tz}`
                 })


                const attributes = ['name', 'birth']
                const promises = attributes.map((attr) =>
                    encrypt_basicInfo(attr === 'name' ? cred.fullName : cred.birthDate, attr, res.user.uid)
                )

                Promise.all(promises)
                    .then((res) => {
                        projectAuth.currentUser
                            .sendEmailVerification({ url: redirect_url })
                            .then(function () {
                                resolve('Email sent.')
                            })
                            .catch(function (error) {
                                reject(error.message)
                            })

                    })

            })
            .catch(function (error) {
                reject(error.message)
            })
    })
}

export default signup
