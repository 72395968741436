import { projectAuth } from './firebase_config'
import { Mixpanel } from '../Analytics/Mixpanel'

/**
 * Send a password reset link to the patient with @param email.
 */
const forgotPassword = (email) => {
    return new Promise((resolve, reject) => {
        projectAuth
            .sendPasswordResetEmail(email)
            .then(function (result) {
                Mixpanel.track('Forgot Password')
                resolve(result)
            })
            .catch(function (error) {
                reject(error.message)
            })
    })
}

export default forgotPassword
