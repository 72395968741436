/**
 * @description This file contains all validation-related helper functions.
 */

/**
 * Check if @param value is an empty string.
 *
 * @param {String} value
 * @return {Boolean} True if @param value is an empty string, false otherwise
 */
export const isEmptyString = (value) => {
    const empty_ws_regex = /\S/
    return !empty_ws_regex.test(value)
}

/**
 * Check if @param value has a valid email format.
 *
 * @param {String} value
 * @return {Boolean} True if @param value has a valid email format, false otherwise
 */
export const isValidEmail = (value) => {
    const email_regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return email_regex.test(value)
}

/**
 * Check if @param value has a valid link format.
 *
 * @param {String} value
 * @return {Boolean} True if @param value has a valid link format, false otherwise
 */
export const isValidLink = (value) => {
    const link_regex =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
    return link_regex.test(value)
}

/**
 * Check if @param value has a valid phone number format.
 *
 * @param {String} value
 * @return {Boolean} True if @param value has a valid phone number format, false otherwise
 */
export const isValidPhoneNumber = (value) => {
    const phone_no_regex = /([\s]+)/g
    return !(value.match(phone_no_regex).length > 1)
}

/**
 * Performs a deep comparison between two values to determine if they are equivalent.
 * Reference: https://lodash.com/docs#isEqual
 *
 * @param {Object} value
 * @param {Object} other
 * @return {Boolean} True if @param value and @param other are equivalent, false otherwise
 */
export const isObjectEqual = require('lodash.isequal')
