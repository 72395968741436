import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Disable a user's authorization for Floka with @param patientId.
 *
 * @param {String} code access code to make API calls
 * @param {String} patientId
 * @return {Promise<any>}
 */
const revokeAccessToken = (userId) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                }
                const params = { userId }
                axios
                    .post(`${baseUrl}/fitbit/revoke`, params, reqBody)
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export default revokeAccessToken
