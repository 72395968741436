import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom'
import {
    CssBaseline,
    Drawer,
    Link,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    AppBar,
    Toolbar,
    IconButton,
    Box
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { DARK_BLUE_MAGENTA } from '../constants/colors'
import { signout } from '../lib/Auth/index'
import { Mixpanel } from '../lib/Analytics/Mixpanel'
import { toDateString } from '../helpers/dateHelpers'
import { exceptFirstLower, firstLetterUpper } from '../helpers/stringHelpers'
import { LANDING } from '../constants/routes'

import { ReactComponent as MenuIcon } from '../assets/Hamburger-menu.svg'
import { ReactComponent as BellIcon } from '../assets/bell.svg'
import { ReactComponent as CalendarIcon } from '../assets/calendar.svg'
import { ReactComponent as HelpCircleIcon } from '../assets/help-circle.svg'
import { ReactComponent as LinkIcon } from '../assets/link.svg'
import { ReactComponent as LogOutIcon } from '../assets/log-out.svg'
import { ReactComponent as ChatIcon } from '../assets/message-square.svg'
import { ReactComponent as TrendingUpIcon } from '../assets/trending-up.svg'
import { ReactComponent as HistoryIcon } from '../assets/history.svg'
import { ReactComponent as UserIcon } from '../assets/user.svg'
import { ReactComponent as RecommendIcon } from '../assets/zap.svg'
import { ReactComponent as CourseIcon } from '../assets/courseIcon.svg'
import { ReactComponent as FileIcon } from '../assets/file.svg'

import { ReactComponent as IntegrationIcon } from '../assets/integrations_logo.svg'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import FlokaLogo from '../assets/floka-logo.svg'
import FlokaLogoWhite from '../assets/floka-logo-white.svg'
import blueBlob from '../assets/blueBlob.svg'
import useWindowDimensions from '../hooks/useWindowDimensions'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
            transition: 'all 2s linear',
        },
        [theme.breakpoints.up('lg')]: {
            width: 260,
        },
    },
    appBar: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            boxShadow: 'none',
        },
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#d7cdea',
        overflowX: 'hidden',
        border: 'none',

        [theme.breakpoints.up('lg')]: {
            width: 260,
        },
    },
    menuItems: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        gap: '25px',
    },
    link: {
        textDecoration: 'none',
    },
    listItemButton: {
        padding: '0',
    },
    listItemIcon: {
        minWidth: '0',
        marginRight: '18px',
        paddingLeft: '15px',
    },
    listItemText: {
        color: '#505050',
        fontSize: '18px',
        [theme.breakpoints.up('lg')]: {
            fontSize: '20px',
        },
        fontFamily: 'Value Serif Pro',
    },
    logoToolbar: {
        width: '95px',
        color:"#fff",
    },
    logoSideBar: {
        width: '174px',
        margin: '26px 33px',
        [theme.breakpoints.up('lg')]: {
            fmargin: '26px 43px',
        },
    },
    menu: {
        position: 'absolute',
        left: '15px',
    },
    sidebarItems: {
        height: '100%',
    },
    signOut: {
        margin: 'auto 0 30px',
        textDecoration: 'none',
    },
    toolbar: {
        backgroundColor: '#000',
        justifyContent: 'center',
    },
    pendingRequest: {
        display: 'flex',
        gap: '16px',
    },
    background: {
        background: `linear-gradient(#e2e7ff, #d7cdea)`,
        //url(${blueBlob}) right 50vh no-repeat
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: '-1',
        margin: 0,
        backgroundColor: '#d7cdea',
    },
    content: {
        backdropFilter: 'blur(100px)',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
}))

function ResponsiveDrawerMenu(props) {
    const classes = useStyles()
    const { list, signOutApp } = props
    const [currentRoute, setCurrentRoute] = useState('/')
    const { width } = useWindowDimensions()
    const [open, setOpen] = useState(false)

    const today = new Date()

    let location = useLocation()

    const handleRouteUpdate = () => setCurrentRoute(location.pathname)

    useEffect(() => handleRouteUpdate())

    const handleSignOut = () => {
        signout()
        Mixpanel.track('Logout', {
            'Logout At': toDateString(today),
        })
        signOutApp()
    }

    const handlePageChange =
        (pageName = '') =>
        () => {
            Mixpanel.track(
                `View ${firstLetterUpper(exceptFirstLower(pageName))} Page`,
                {
                    'View At: ': toDateString(today),
                }
            )
        }

    const handleDrawerToggle = () => {
        setOpen(!open)
    }

    const sideBarItems = (
        <List disablePadding className={classes.menuItems}>
            {list.map((item) => {
                let fontColor = matchPath(currentRoute, item.route)
                    ? DARK_BLUE_MAGENTA
                    : '#505050'
                return (
                    <RouterLink
                        to={item.route}
                        key={item.text}
                        className={classes.link}
                        onClick={handlePageChange(item.text)}
                    >
                        <ListItem
                            button
                            key={item.text}
                            className={classes.listItemButton}
                        >
                            {
                            item.text === 'Courses' ? (
                                <ListItemIcon className={classes.listItemIcon}>
                                    <CourseIcon />
                                </ListItemIcon>
                            ) :
                            item.text === 'PROGRAMS' ? (
                                <ListItemIcon className={classes.listItemIcon}>
                                    <CalendarIcon />
                                </ListItemIcon>
                            ) : item.text === 'INVITATIONS' ? (
                                <ListItemIcon className={classes.listItemIcon}>
                                    <RecommendIcon />
                                </ListItemIcon>
                            ) : item.text === 'HEALTH DATA' ? (
                                <ListItemIcon className={classes.listItemIcon}>
                                    <TrendingUpIcon />
                                </ListItemIcon>
                            )
                            : item.text === 'HEALTH JOURNEY' ? (
                                <ListItemIcon className={classes.listItemIcon}>
                                    <HistoryIcon />
                                </ListItemIcon>
                            ): 
                            item.text === 'Files' ? (
                                <ListItemIcon className={classes.listItemIcon}>
                                    <FileIcon />
                                </ListItemIcon>
                            )  : 
                            item.text === 'Forms' ? (
                                <ListItemIcon className={classes.listItemIcon}>
                                    <FileIcon />
                                </ListItemIcon>
                            )  : 
                            item.text === 'Notifications' ? (
                                <ListItemIcon className={classes.listItemIcon}>
                                    <BellIcon />
                                </ListItemIcon>
                            )  : 
                            item.text === 'CHAT' ? (
                                <ListItemIcon className={classes.listItemIcon}>
                                    <ChatIcon />
                                </ListItemIcon>
                            ) : item.text === 'SUBSCRIPTIONS' ? (
                                <ListItemIcon className={classes.listItemIcon}>
                                    <LinkIcon />
                                </ListItemIcon>
                            ) : 
                            item.text === 'INTEGRATIONS' ? (
                                <ListItemIcon className={classes.listItemIcon}>
                                    <IntegrationIcon />
                                </ListItemIcon>
                            )  : 
                            (
                                <ListItemIcon className={classes.listItemIcon}>
                                    <UserIcon />
                                </ListItemIcon>
                            )}
                            <ListItemText
                                disableTypography
                                style={{ color: fontColor }}
                                primary={
                                    item.text === 'Notifications' && item.showDot ?
                                    (
                                        <Box className={classes.pendingRequest}>
                                            <b>Notifications</b>
                                            <FiberManualRecordIcon fontSize="small" />
                                        </Box> 
                                    ):
                                    item.text === 'SUBSCRIPTIONS'
                                        ? 'My Coaches'
                                        : item.text === 'RECOMMEND'
                                        ? 'Recommendations'
                                        : exceptFirstLower(item.text)
                                }
                                className={classes.listItemText}
                            />
                        </ListItem>
                    </RouterLink>
                )
            })}
            <Link
                href='https://foregoing-canoe-d1b.notion.site/Fl-ka-University-Web-Android-e188e35aa1f04c5ba3d7a0c2dce8e18d'
                target="_blank"
                className={classes.link}
                underline="none"
            >
                <ListItem button className={classes.listItemButton}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <HelpCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={'Tutorial'}
                        className={classes.listItemText}
                    />
                </ListItem>
            </Link>
            <RouterLink
                to="/"
                onClick={handleSignOut}
                className={classes.signOut}
            >
                <ListItem button className={classes.listItemButton}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <LogOutIcon />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={'Sign Out'}
                        className={classes.listItemText}
                    />
                </ListItem>
            </RouterLink>
        </List>
    )

    return (
        <>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        color="inherit"
                        aria-label="menu"
                        onClick={handleDrawerToggle}
                        className={classes.menu}
                    >
                        <MenuIcon />
                    </IconButton>
                    <RouterLink to={LANDING}>
                        <img
                            src={FlokaLogoWhite}
                            className={classes.logoToolbar}
                            alt="floka-logo"
                        />
                    </RouterLink>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
                <Drawer
                    variant="temporary"
                    open={open}
                    onClose={handleDrawerToggle}
                    onClick={handleRouteUpdate}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <RouterLink to={LANDING}>
                        <img
                            src={FlokaLogo}
                            className={classes.logoSideBar}
                            alt="floka-logo"
                        />
                    </RouterLink>
                    <div className={classes.sidebarItems}>
                        <div className={classes.background}>
                            <div className={classes.content} />
                        </div>
                        {sideBarItems}
                    </div>
                </Drawer>
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                    onClick={handleRouteUpdate}
                >
                    <RouterLink to={LANDING}>
                        <img
                            src={FlokaLogo}
                            className={classes.logoSideBar}
                            alt="floka-logo"
                        />
                    </RouterLink>
                    <div className={classes.sidebarItems}>
                        <div className={classes.background}>
                            <div className={classes.content} />
                        </div>
                        {sideBarItems}
                    </div>
                </Drawer>
            </nav>
        </>
    )
}

ResponsiveDrawerMenu.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            route: PropTypes.string.isRequired,
        })
    ).isRequired,
    signOutApp: PropTypes.func.isRequired,
}

export default ResponsiveDrawerMenu
