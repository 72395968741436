import { projectAuth, auth } from './firebase_config'

/**
 * Persist the session in a tab, and clear it when the tab or window is closed
 *
 * Source: https://firebase.google.com/docs/auth/web/auth-state-persistence#web-v8
 * @return {Promise}
 */
const setPersistence = (email, password) => {
    return new Promise((resolve, reject) => {
        projectAuth
            .setPersistence(auth.Auth.Persistence.SESSION)
            .then(() => {
                // Existing and future Auth states are now persisted in the current
                // session only. Closing the window would clear any existing state even
                // if a user forgets to sign out.
                // ...
                // New sign-in will be persisted with session persistence.
                resolve(projectAuth.signInWithEmailAndPassword(email, password))
            })
            .catch((error) => {
                // Handle Errors here.
                // var errorCode = error.code;
                var errorMessage = error.message
                reject(errorMessage)
            })
    })
}

export default setPersistence
