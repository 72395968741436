/** Patient Class */

export default class Patient {
    constructor(patDbModel) {
        // console.log('patDbModel', patDbModel);
        if (typeof patDbModel !== 'undefined') {
            // Construct new Patient Object with pracDbModel
            this.age = patDbModel.age
            this.firstName = patDbModel.firstName
            this.practitioner = patDbModel.practitioner
            this.pracId = patDbModel.pracId
            this.stripeId = patDbModel.stripe_id
            this.subscriptions = patDbModel.subscriptions
            this.data = patDbModel.data
        } else {
            // Construct new Patient Object using default values
            this.age = ''
            this.firstName = ''
            this.practitioner = {}
            this.pracId = ''
            this.stripeId = ''
            this.subscriptions = []
            this.data = {}
        }
    }
}
