import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'


export const updateLastData = (id) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                }
                const params = {
                    patientID: id
                }
                axios
                    .post(`${baseUrl}/tempdrop/update_data`, params, reqBody)
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(err))
            })
            .catch(function (error) {
                reject(error)
            })
    })
}
