import { projectAuth, facebookProvider } from './firebase_config'

/**
 * Signin patient using Facebook.
 */
const signinFacebook = () => {
    return new Promise((resolve, reject) => {
        projectAuth
            .signInWithPopup(facebookProvider)
            .then((result) => {
                /** @type {firebase.auth.OAuthCredential} */
                // var credential = result.credential;

                // The signed-in user info.
                // var user = result.user;

                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                // var accessToken = credential.accessToken;

                resolve(result)
            })
            .catch((error) => reject(error.message))
    })
}

export default signinFacebook
