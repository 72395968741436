import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Get a list of invitation items for a patient with @param patEmail.
 *
 * @param {String} patEmail
 * @return {Promise<any>}
 */
const getInvitationsByIds = (patientId) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                }

                axios
                    .get(
                        `${baseUrl}/adminPatient/invitationsById/${patientId}`,
                        reqBody
                    )
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export default getInvitationsByIds