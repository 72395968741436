import React from 'react'
import { Route } from 'react-router-dom'
import { projectAuth } from '../lib/Auth/firebase_config'

function PrivateRoute({ component: Component, ...rest }) {
    const user = projectAuth.currentUser
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                user && user !== null && <Component {...routeProps} {...rest} />
            }
        />
    )
}

export default PrivateRoute
