import axios from 'axios'
import { baseStripeUrl } from '../config'

/**
 * Create a new product.
 *
 * @param {String} email
 * @return {Promise<any>}
 */
const createProduct = (params) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${baseStripeUrl}/create_product`, params)
            .then((res) => resolve(res.data))
            .catch((err) => reject(JSON.stringify(err)))
    })
}

export default createProduct
