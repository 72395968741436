import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { CHAT } from '../../constants/routes'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FeedbackIcon from '@material-ui/icons/Feedback'
import IconButton from '@material-ui/core/IconButton'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import HelpIcon from '@material-ui/icons/Help'
import QuestionAnswerRoundedIcon from '@material-ui/icons/QuestionAnswerRounded'
import MessageIcon from '@material-ui/icons/Message'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { BLACK, WHITE } from '../../constants/colors'

const useStyles = makeStyles((theme) => ({
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
        '& .MuiSpeedDial-actions': {
            paddingLeft: '100px',
        },
    },
    icon: {
        width: '100px',
        textAlign: 'center',
    },
    text: {
        color: WHITE,
        fontSize: '14px',
    },
}))

const actions = [
    {
        icon: (
            <IconButton
                href="https://floka.typeform.com/to/upO98Pia"
                target="_blank"
            >
                <HelpIcon color="secondary" />
            </IconButton>
        ),
        name: 'Support Ticket',
    },
    {
        icon: (
            <IconButton
                href="https://foregoing-canoe-d1b.notion.site/Fl-ka-University-Web-Android-e188e35aa1f04c5ba3d7a0c2dce8e18d"
                target="_blank"
            >
                <QuestionAnswerRoundedIcon color="secondary" />
            </IconButton>
        ),
        name: 'Client FAQ',
    },
    {
        icon: (
            <IconButton href="https://m.me/flokalife" target="_blank">
                <MessageIcon color="secondary" />
            </IconButton>
        ),
        name: 'Chat',
    },
    {
        icon: (
            <IconButton
                href="https://calendly.com/floka/support-session?month=2021-04"
                target="_blank"
            >
                <CalendarTodayIcon color="secondary" />
            </IconButton>
        ),
        name: 'Support Session',
    },
]

function FeedbackButton() {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const matchChatRoute = useRouteMatch(CHAT)

    const handleOpen = () => setOpen(true)

    const handleClose = () => setOpen(false)

    return (
        <SpeedDial
            ariaLabel="feedback-speeddial"
            className={classes.speedDial}
            icon={
                <React.Fragment>
                    <FeedbackIcon className={classes.extendedIcon} />
                    <Typography className={classes.text}>
                        How can we help?
                    </Typography>
                </React.Fragment>
            }
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            hidden={matchChatRoute ? true : false}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={
                        <Typography variant="body2" className={classes.icon}>
                            {action.name}
                        </Typography>
                    }
                    tooltipOpen
                    onClick={handleClose}
                />
            ))}
        </SpeedDial>
    )
}

export default FeedbackButton
