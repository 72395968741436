import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Mark a content or superset item as complete or missed in Program-Schedules table in dynamoDB.
 *
 * @params {Obj} data
 * @return {Promise<any>}
 */
const updateContent = (data) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                const reqBody = { headers: { Authorization: `${idToken}` } }
                const params = {
                    scheduleIndex: data.scheduleIndex,
                    contentIndex: data.contentIndex,
                    supersetIndex: data.supersetIndex,
                    label: data.label,
                    value: data.value,
                }
                axios
                    .put(
                        `${baseUrl}/adminPatient/schedules/${data.programId}/content/update`,
                        params,
                        reqBody
                    )
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((error) => reject(error))
    })
}

export default updateContent
