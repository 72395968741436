import React, { useState } from 'react'

import {
    makeStyles,
    Grid,
    Typography,

} from '@material-ui/core'

import TempDropLogo from '../../assets/tempdrop-logo.png'
import CachedIcon from '@mui/icons-material/Cached'
import { updateLastData } from '../../lib/Api/TempDrop/updateLastData'
import { disconnectTempdrop } from '../../lib/Api/TempDrop/disconnectTempdrop'
import ErrorSnackbar from '../../elements/ErrorSnackBar'
import Notification from '../../elements/Notification'



const useStyles = makeStyles((theme) => ({
    card: {
        cursor: 'pointer'
    },
    gridContainer: {
        width: '-webkit-fill-available',
    },
    divider: {
        backgroundColor: '#999999',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    logo: {
        width: '50px',
        height: '50px',
    },
    link: {
        textDecoration: 'none',
        color: '#000',
    },
    activeSince: {
        marginTop: '5%',
        fontSize: '12px',
        color: '#737373',
    },
    gridItem: {
        border: 'solid 1px #ebebeb',
        borderRadius: '12px',
        textAlign: 'center',
        padding: '8px',
        width: '180px',
        marginBottom: '10px',
    },
    dispensaryLink: {
        textAlign: 'start',
        marginBottom: '24px',
    },
    disconnect: {
        cursor: 'pointer',
        background: 'none',
        border: 'solid 1px #ff6060',
        textAlign: 'start',
        color: '#ff6060',
        padding: '10px 25px',
        borderRadius: '4px',
        '&:hover': {
            background: 'rgb(255,96,96,0.2)',
        },
    },
    inputButtonIntegrate: {
        padding: '8px 10px',
        fontSize: '0.8125rem',
        color: '#000',
        border: 'none',
        boxShadow: 'none',
        backgroundColor: '#d2d5f7',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#bfc4fd',
        },
    },
    inputButtonAccess: {
        width: '80%',
        padding: '8px 10px',
        fontSize: '0.8125rem',
        color: 'white',
        border: 'none',
        boxShadow: 'none',
        backgroundColor: '#ff6666',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#ff3232',
        },
    },
    lastSyncText: {
        fontSize: '10px'
    },
    options: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    spinRefresh: {
        animation: "$spin 3s linear infinite"

    },
    "@keyframes spin": {
        "0%": { transform: "rotate(360deg)" },
        "100%": { transform: "rotate(0deg)" }
    }

}))

const TdContent = ({ data, id, initialTempDrop }) => {
    const classes = useStyles()


    const [updatingData, setUpdatingData] = useState(false)
    const [error, setError] = useState('')
    const [notification, setNotification] = useState('')


    const closeError = () => setError('')
    const closeNotification = () => setNotification('')

    const updateTempDropData = async () => {

        if (!updatingData) {
            setUpdatingData(true)

            try {
                const response = await updateLastData(id)
                data.date = response
                setNotification('Your data has been updated!')


            } catch (err) {

                if (err.response.data === "Error: No Data to sync.") {
                    setNotification('Data is up to date! No data to sync.')
                } else {
                    setError('Server error, please try again!')
                }
            }
            setUpdatingData(false)


        }

    }

    const handleDisconnectTempDrop = async () => {

        try {

            await disconnectTempdrop(id)
            setNotification('TempDrop disconnected!')

            initialTempDrop()

        } catch (err) {
            console.log(err.message)
            setError('Server error, please try again!')

        }

    }

    return (
        <Grid
            container
            direction="row"
            spacing={2}
            className={classes.card}

        >
            <Grid
                item
                container
                direction="column"
                justifyContent="center"
                xs={12}
            >
                <Grid item>
                    <img src={TempDropLogo} className={classes.logo} />
                </Grid>
                <Typography variant="body2" gutterBottom>
                    <b>TempDrop</b>
                </Typography>
                <Typography variant="body2" paragraph>
                    Connect your BBT data to floka
                </Typography>
                {!data.url ? <>
                    <div className={classes.options} >

                        <input
                            className={classes.inputButtonAccess}
                            type="button"
                            value={"Disconnect"}
                            onClick={handleDisconnectTempDrop}
                        />

                        {!data.url && <CachedIcon className={updatingData ? classes.spinRefresh : null} onClick={updateTempDropData} />}

                    </div>

                    <p className={classes.lastSyncText}>Last Sync: {data.date} </p>

                </> : null}

                {data.url ? <input
                    className={classes.inputButtonIntegrate}
                    type="button"
                    value={"Integrate Now"}
                /> : null}
            </Grid>
            <ErrorSnackbar error={error} onClose={closeError} />
            <Notification text={notification} onClose={closeNotification} />
        </Grid>
    )
}

export default TdContent