import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Get a list of practitioner items from dynamoDB with @param pracId.
 *
 * @return {Promise<any>}
 */
const getMultiPractitioners = (patientId) => {

    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                }
                const params = {
                    patientId: patientId,
                }
                axios
                    .post(
                        `${baseUrl}/adminPatient/multiPractitioner`,
                        params,
                        reqBody
                    )
                    .then((res) => {
                        const data = res.data
                        resolve(data)
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export default getMultiPractitioners