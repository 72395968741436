import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Get notifications from a practitioner from dynamoDB.
 *
 * @return {Promise<any>}
 */
const getNotifications = (patId) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then((idToken)=> { 
                const reqBody = { headers: { Authorization: `${idToken}` } }
                axios
                    .get(`${baseUrl}/patient/${patId}/notifications`, reqBody)
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((error) => reject(error))
    })
}

export default getNotifications
