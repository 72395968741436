import { get_patient_data, getPatientDataNoPractitioner } from '.'
import Practitioner from '../../../classes/Practitioner'
import { database } from '../../Auth/firebase_config'
import { decrypt, getAge, decryptPatient } from '../../Patient/index'
import { get_practitioner } from '../Practitioner/index'
import getMeals from './getMeals'

/**
 * Fetch all patient-related data from Firebase in @param patientId.
 *
 * @param {String[]} patientId - Patient's Firebase ids
 * @return {Promise<any>}
 */
const getProfile = (patientId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const patientRef = database.ref(`/users/${patientId}`)
            patientRef.on(
                'value',
                (snapshot) => {
                    const data = snapshot.val()
                    const basicInfo = data?.basicInfo
                    const connectedDevices = data?.connectedDevices
                    const practitioner = data?.practitioner
                    const fitbitAuth = data?.fitbitToken?.refreshToken

                    
                    
                    // If user has no data OR user has already logged into iOS
                    if (data === null || !data || !basicInfo)
                        localStorage.setItem('isFirstLogin', true)
                    else localStorage.setItem('isFirstLogin', false)

                    let user = {
                        firstName: 'None',
                        age: 'Not available',
                        qbId: '',
                    }
                    if (basicInfo) {
                        const firstName =
                            decrypt(basicInfo?.name) ||
                            decryptPatient(basicInfo?.name) ||
                            'None'
                        user.firstName =
                            firstName.charAt(0).toUpperCase() +
                            firstName.slice(1)
                        user.age = getAge(basicInfo?.birth) || 'Not available'
                    }

                    console.log(fitbitAuth)
                    if(fitbitAuth && fitbitAuth?.length){
                        console.log('this user is connected to fitbit')
                        console.log(fitbitAuth)
                        user.fitbitAuth = true
                    }
                    // Add connected devices attr
                    if (
                        connectedDevices &&
                        Object.keys(connectedDevices).length
                    ) {
                        const chartData = { ...data }
                        delete chartData.basicInfo
                        user.data = { connectedDevices, ...chartData }
                    } else {
                        const chartData = { ...data }
                        delete chartData.basicInfo
                        user.data = { ...chartData }
                    }

                    // User is not subscribed to a practitioner (see FB real-time DB)
                    // Ensure patient have cycleEntries when don't have patient ID
                    if (!practitioner) {

                        // getMeals(patientId)
                        getPatientDataNoPractitioner(patientId)
                            .then((res) => {
                                user.data = Object.values(res)[0]
                                resolve(user)
                            })
                            .catch((err) =>
                                console.log('getPatientDataNoPractitioner::error - ', err)
                            )
                        return
                    }

                    const pracId = Object.keys(practitioner)
                    user.pracId = pracId?.[0]

                    get_practitioner(pracId?.[0])
                        .then((data) => {
                            // console.log('here')
                            user.practitioner = new Practitioner(data)
                            get_patient_data(patientId, pracId[0])
                                .then((res) => {
                                    if (
                                        connectedDevices &&
                                        Object.keys(connectedDevices).length
                                    ) {
                                        user.data = { ...res, connectedDevices }
                                    } else {
                                        user.data = res
                                    }
                                    resolve(user)
                                })
                                .catch((err) => {
                                    console.log(
                                        'getProfile::get_patient_data::error - ',
                                        err
                                    )
                                })
                        })
                        .catch((err) =>
                            console.log(
                                'getProfile::get_practitioner::error - ',
                                err
                            )
                        )
                },
                function (err) {
                    console.log('basicInfoRef::err -', err)
                }
            )
        } catch (err) {
            reject(`getProfile::error - ${JSON.stringify(err)}`)
        }
    })
}

export default getProfile
