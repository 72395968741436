import { useState, useEffect } from 'react'

function debounce(fn, ms) {
    let timer
    return (_) => {
        clearTimeout(timer)
        timer = setTimeout((_) => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    }
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height,
    }
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    )

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }, 1000)

        window.addEventListener('resize', debouncedHandleResize)
        return () => window.removeEventListener('resize', debouncedHandleResize)
    }, [])

    return windowDimensions
}
