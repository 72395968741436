import { useEffect, useReducer, useState } from 'react'
import Patient from '../classes/Patient'
import { HISTORY, INTEGRATIONSPAGE } from '../constants/routes'
import { secondsToDate } from '../helpers/dateHelpers'
import { Mixpanel } from '../lib/Analytics/Mixpanel'
import { revoke_access_token, sync_fitbit } from '../lib/Api/Fitbit'
import { sync_data } from '../lib/Api/GoogleFit'
import {
    encrypt_basicInfo,
    get_patient_data,
    get_profile,
    update_basic_info,
} from '../lib/Api/Patient'
import { updateLastData } from '../lib/Api/OuraIntegration/updateLastData'

import { create_account, retrieve_account } from '../lib/Api/Stripe'
import { signinFacebook, signinGoogle } from '../lib/Auth'
import {
    updateFbCycleEntry,
    deleteCycleEntry,
    updateCoverLine,
    updateBBT,
    addNutritionData,
    addNutritionDataFirebase,
    deleteNutritionData,
    deleteNutritionDataFirebase,
    updateNutritionData
} from '../lib/Patient'
import { get_notifications } from '../lib/Api/Notification'

/** Reducer accepts an action type and returns the current state, dispatch pair. */

/** Action types */
const SET_USER = 'SET_USER'

/** Reducer switch statements */
const reducer = (state, action) => {
    switch (action.type) {
        case SET_USER:
            return { ...state, user: action.value }
        default:
            throw new Error(
                `App::reducer::error - Invalid action type: ${action.type}`
            )
    }
}

/** Return App initial state */
const initApp = () => {
    return {
        isAuth: false,
        user: { loggedIn: false },
    }
}

const useAppData = () => {
    const [state, dispatch] = useReducer(reducer, initApp())
    const [refresh, setRefresh] = useState(true)
    const [tracking, setTracking] = useState(false);
    const [acceptInvite, setAcceptInvite] = useState(false)
    const [shownNotification, setShownNotification] = useState(false)

    // Set methods for each state
    const setUser = (user) => dispatch({ type: SET_USER, value: user })

    useEffect(() => {
        async function getNotificationViewed(userId) {
            const notification = await get_notifications(userId)
            console.log('notification', notification)
            if (notification.Viewed === false) {
                setShownNotification(true)
            }
        }

        if (state.user.uid) {
            console.log('goes here')
            getNotificationViewed(state.user.uid)
        }

    }, [state.user.uid, shownNotification])

    useEffect(() => {
        if (state.user.uid && state.user.email) {
            // 1. Get patients firebase basic and practitioner information
            get_profile(state.user.uid)
                .then(async (fbData) => {
                    console.log('FBData', fbData)
                    // if(fbData?.data?.ouraToken){
                    //     await updateLastData(state.user.uid)
                    // }

                    if (fbData.data?.tracking) {
                        for (let i = 0; i < Object.keys(fbData.data?.tracking).length; i++) {
                            if (fbData.data?.tracking[Object.keys(fbData.data?.tracking)[i]].isConsented === false) {
                                setTracking(true)
                            }

                        }

                    }

                    if (fbData.data?.acceptInvite) {
                        setAcceptInvite(true)

                    }
                    // 2. Get patients stripe account details
                    retrieve_account(state.user.email)
                        .then((stripeData) => {
                            // console.log('customer data', stripeData);
                            const stripeId = stripeData.customers[0].id
                            const subscriptions =
                                stripeData.customers[0].subscriptions.data
                            const customer = new Patient({
                                stripe_id: stripeId,
                                subscriptions,
                                ...fbData,
                            })
                            setUser({ ...state.user, ...customer })
                        })
                        .catch(async (err) => {
                            // console.log('retrieve_account::err - ', err);
                            if (
                                fbData.firstName &&
                                state.user.email &&
                                state.user.uid
                            ) {
                                // 2b. If stripe account doesn't exist, create a new stripe account.
                                await create_account(
                                    fbData.firstName,
                                    state.user.email,
                                    state.user.uid
                                ).then((data) => {
                                    // console.log('new customer data', data);
                                    Mixpanel.track('Create new stripe customer')
                                    const newStripeId = data.id
                                    const newSubscriptions =
                                        data.subscriptions.data
                                    const newCustomer = new Patient({
                                        stripe_id: newStripeId,
                                        subscriptions: newSubscriptions,
                                        ...fbData,
                                    })
                                    setUser({ ...state.user, ...newCustomer })
                                })
                                .catch(()=> {
                                    console.log('this is a new error')
                                    const newCustomer = new Patient({
                                        stripe_id: '123',
                                        subscriptions: [],
                                        ...fbData,
                                    })
                                    console.log(newCustomer)
                                    console.log({ ...state.user, ...newCustomer })
                                    setUser({ ...state.user, ...newCustomer })

                                })
                            } else {
                                console.log('does it really rwach here?')
                                const newCustomer = new Patient({
                                    stripe_id: '123',
                                    subscriptions: [],
                                    ...fbData,
                                })
                                setUser({ ...state.user, ...newCustomer })
                            }
                        })
                })
                .catch((err) => console.log('get_profile::err - ', err))
        }
    }, [state.user.uid, refresh, tracking])

    const handleSyncGoogleFit = (userId, code) => {
        sync_data(userId, code)
            .then((res) => {
                const today = new Date()
                Mixpanel.track('Sync google fit')
                localStorage.setItem('syncedGoogleFit', true)
                localStorage.setItem('lastSyncedAtGoogleFit', today)
                get_profile(userId)
                    .then((data) => {
                        setUser({ ...state.user, ...data })
                        window.location.assign(INTEGRATIONSPAGE)
                    })
                    .catch((err) => console.log('get_profile::err - ', err))
            })
            .catch((err) => console.log('sync_data::err - ', err))
    }

    const handleSyncFitbit = (token, userId, allowedScopes, tokenExpiredAt) => {
        encrypt_basicInfo(token, 'fbToken', userId).then((data) => {
            let today = new Date()
            today = new Date(today.getTime() + tokenExpiredAt * 1000)
            today = today.getTime()
            const expiredAt = secondsToDate(today)

            update_basic_info(expiredAt, 'expiredFbTokenAt', userId).then(
                (data) => {
                    sync_fitbit(token, userId, allowedScopes)
                        .then((data) => {
                            Mixpanel.track('Sync fitbit')
                            get_profile(userId)
                                .then((data) => {
                                    setUser({ ...state.user, ...data })
                                    window.location.assign(INTEGRATIONSPAGE)
                                })
                                .catch((err) =>
                                    console.log('get_profile::err - ', err)
                                )
                        })
                        .catch((err) => console.log('sync_fitbit::err -', err))
                }
            )
        })
    }

    const handleRevokeFitbit = (token, userId) => {
        console.log('hereeeee', token, userId)
        revoke_access_token(userId)
            .then((data) => {
                // const attributes = ['fbToken', 'expiredFbTokenAt']
                // const promises = attributes.map((attr) =>
                //     update_basic_info(null, attr, userId)
                // )
                // Promise.all(promises)
                //     .then(([...data]) => {
                //         Mixpanel.track('Revoke Fitbit access token')
                //         get_profile(userId)
                //             .then((data) => {
                //                 setUser({ ...state.user, ...data })
                //                 window.location.assign(INTEGRATIONSPAGE)

                //             })
                //             .catch((err) =>
                //                 console.log('get_profile::err - ', err)
                //             )
                //     })
                //     .catch((err) =>
                //         console.log('revoke_access_token::err -', err)
                //     )


                get_profile(userId)
                    .then((data) => {
                        setUser({ ...state.user, ...data })
                        window.location.assign(INTEGRATIONSPAGE)

                    })
                    .catch((err) =>
                        console.log('get_profile::err - ', err)
                    )
            })
            .catch((err) => console.log('revoke_fb_access_token::err -', err))
    }

    const handleSigninWithGoogle = () => {
        signinGoogle()
            .then((res) => {
                Mixpanel.track('Signed in with Google')
                if (Object.keys(res).length)
                    setUser({ ...state.user, firstName: Object.values(res)[0] })
            })
            .catch((error) => console.log('signinGoogle::error -', error))
    }

    const handleSigninWithFacebook = () => {
        signinFacebook()
            .then((res) => { })
            .catch((error) => console.log('signinFacebook::error -', error))
    }

    /**
     * Format cycle entry.
     *
     * @param {Object} obj
     * @param {Boolean} bool
     * @param {String} type
     * @returns {Object} obj
     */
    function formatEntry(obj, bool, type) {
        if (!obj && type === 'menstrual')
            obj = { bleeding: bool, ovulating: !bool }
        else if (!obj && type === 'ovulation')
            obj = { ovulating: bool, bleeding: !bool }
        else if (obj && type === 'menstrual') {
            obj.bleeding = bool
            obj.ovulating = !bool
        } else {
            obj.ovulating = bool
            obj.bleeding = !bool
        }
        return obj
    }

    /**
     * Update cycle entry through backend route:.
     *
     * @param {Object} params
     */
    const handleUpdateCycleEntry = async (params) => {
        const patientId = state.user.uid;
        await updateFbCycleEntry(params, patientId)
            .then((data) => {
                Mixpanel.track('Update cycle entry')
                // if (state.user.pracId) {
                //     get_patient_data(state.user.uid, state.user.pracId)
                //         .then(async (data) => {
                //             setUser({ ...state.user, data: data })
                //         })
                //         .catch((err) =>
                //             console.log('get_patient_data::err:: -', err)
                //         )
                // } else {
                //     get_profile(state.user.uid)
                //         .then((data) => {
                //             setUser({ ...state.user, ...data })
                //         })
                //         .catch((err) => console.log('get_profile::err - ', err))
                // }
            })
            .catch((err) => console.log('update_fb_cycle_entry::err:: -', err))
    }


    const refreshAppData = () => {

        setRefresh((prev) => {
            return !prev
        })
    }

    /**
    * Delete cycle entry through backend route:.
    *
    * @param {Object} params
    */

    const handleDeleteCycleEntry = async (params) => {
        const patientId = state.user.uid;
        await deleteCycleEntry(params, patientId)
            .then((data) => {
                Mixpanel.track('Update cycle entry')
                // if (state.user.pracId) {
                //     get_patient_data(state.user.uid, state.user.pracId)
                //         .then(async (data) => {
                //             setUser({ ...state.user, data: data })
                //         })
                //         .catch((err) =>
                //             console.log('get_patient_data::err:: -', err)
                //         )
                // } else {
                //     get_profile(state.user.uid)
                //         .then((data) => {
                //             setUser({ ...state.user, ...data })
                //         })
                //         .catch((err) => console.log('get_profile::err - ', err))
                // }
            })
            .catch((err) => console.log('delete_fb_cycle_entry::err:: -', err))

    }


    /**
     * Update patient BBT coverline through backend route:.
     *
     * @param {Object} params
     */


    const handleUpdateCoverLine = (params) => {
        const patientId = state.user.uid;
        updateCoverLine(params, patientId)
            .then((data) => {
                Mixpanel.track('Update coverline')
                if (state.user.pracId) {
                    get_patient_data(state.user.uid, state.user.pracId)
                        .then(async (data) => {
                            setUser({ ...state.user, data: data })
                        })
                        .catch((err) =>
                            console.log('get_patient_data::err:: -', err)
                        )
                } else {
                    get_profile(state.user.uid)
                        .then((data) => {
                            setUser({ ...state.user, ...data })
                        })
                        .catch((err) => console.log('get_profile::err - ', err))
                }
            })
            .catch((err) => console.log('update_coverline::err:: -', err))

    }


    /**
 * Update patient BBT temperature through backend route:.
 *
 * @param {Object:} params
 */

    const handleUpdateBBT = (params) => {
        const patientId = state.user.uid;
        updateBBT(params, patientId)
            .then((data) => {
                Mixpanel.track('Update bbt temperature')
                if (state.user.pracId) {
                    get_patient_data(state.user.uid, state.user.pracId)
                        .then(async (data) => {
                            setUser({ ...state.user, data: data })
                        })
                        .catch((err) =>
                            console.log('get_patient_data::err:: -', err)
                        )
                } else {
                    get_profile(state.user.uid)
                        .then((data) => {
                            setUser({ ...state.user, ...data })
                        })
                        .catch((err) => console.log('get_profile::err - ', err))
                }
            })
            .catch((err) => console.log('update_BBT::err:: -', err))

    }

    /**
    * Add patient nutrition meal data through backend route into dyanmoDb:
    *
    * @param {Object} params
    */

    const handleNutritionAdd = (params) => {
        const patientId = state.user.uid;
        console.log(patientId);
        addNutritionData(params, patientId)
            .then((data) => {
                Mixpanel.track('Update Nutrition Meal Entry')
            })
            .catch((err) => console.log('update_Nutrition::err:: -', err))

    }

    /**
     * Add patient nutrition meal data through backend route into firebase:
     *
     * @param {Object} params
     */

    const handleNutritionAddFirebase = (params) => {
        const patientId = state.user.uid;
        console.log(params)
        console.log(patientId);
        addNutritionDataFirebase(params, patientId)
            .then((data) => {
                Mixpanel.track('Update Nutrition Meal Entry')
            })
            .catch((err) => console.log('update_Nutrition::err:: -', err))

    }



    /**
     * Delete patient nutrition meal data through backend route:.
     *
     * @param {Object} params
     */

    const handleNutritionDelete = (params) => {
        const patientId = state.user.uid;
        deleteNutritionData(params, patientId)
            .then((data) => {
                Mixpanel.track('delete Nutrition Meal Entry')
            })
            .catch((err) => console.log('delete_Nutrition::err:: -', err))

    }

    /**
    * Delete patient nutrition meal data in firebase:.
    *
    * @param {Object} params
    */

    const handleNutritionDeleteFirebase = (params) => {
        const patientId = state.user.uid;
        deleteNutritionDataFirebase(params, patientId)
            .then((data) => {
                Mixpanel.track('delete Nutrition Meal Entry')
            })
            .catch((err) => console.log('delete_Nutrition::err:: -', err))

    }




    /**
    * Updte patient nutrition meal data through backend route:.
    *
    * @param {Object} params
    */

    const handleNutritionUpdate = (params) => {
        const patientId = state.user.uid;
        updateNutritionData(params, patientId)
            .then((data) => {
                Mixpanel.track('update Nutrition Meal Entry')
            })
            .catch((err) => console.log('update_Nutrition::err:: -', err))



    }






    return {
        state,
        refresh,
        tracking,
        shownNotification,
        setShownNotification,
        acceptInvite,
        setAcceptInvite,
        setUser,
        setTracking,
        handleUpdateCycleEntry,
        handleDeleteCycleEntry,
        handleUpdateCoverLine,
        handleUpdateBBT,
        handleNutritionAdd,
        handleNutritionAddFirebase,
        handleNutritionDelete,
        handleNutritionDeleteFirebase,
        handleNutritionUpdate,
        handleSigninWithGoogle,
        handleSigninWithFacebook,
        handleSyncGoogleFit,
        handleSyncFitbit,
        handleRevokeFitbit,
        refreshAppData
    }
}

export default useAppData
