import axios from 'axios'
import { baseStripeUrl } from '../config'

/**
 * Retrieve the latest invoice from the latest subscription.
 *
 * @param {String} id
 * @return {Promise<any>}
 */
const getInvoice = (id) => {
    return new Promise((resolve, reject) => {
        const params = { invoice_id: id }

        axios
            .post(`${baseStripeUrl}/invoice`, params)
            .then((res) => resolve(res.data))
            .catch((err) => reject(JSON.stringify(err)))
    })
}

export default getInvoice
