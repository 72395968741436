import { Co2Sharp } from "@mui/icons-material"

/**
 * Convert date object to a string in the UTC format.
 * Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/UTC
 *
 * @param {Date} d  Date object
 * @return {String}  Date string in the UTC format
 */
export const toDateString = (d) => {
    return new Date(
        Date.UTC(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
        )
    ).toUTCString()
}

/**
 * Convert date string to the string format of Jan 01 2021.
 * @param stringDate must be in format of yyyy-mm-dd.
 *
 * @param {String} stringDate
 * @return {String} result string in the format of day month year
 */
export const getDayMonthYear = (stringDate) => {
    let result
    let newDateString = new Date(stringDate.replace(/-/g, '/')).toDateString()
    let splitString = newDateString.split(' ')
    result = splitString[2] + ' ' + splitString[1] + ' ' + splitString[3]
    return result
}

export const getDayMonth = (d, stringDay) => {
    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ]
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    const day = days[d.getDay()]
    const suffix =
        (stringDay >= 4 && stringDay <= 20) ||
        (stringDay >= 24 && stringDay <= 30)
            ? 'th'
            : ['st', 'nd', 'rd'][(stringDay % 10) - 1]

    const month = months[d.getMonth()]
    return `${day}, ${month} ${stringDay}${suffix}`
}

/**
 * Get next month date.
 *
 * @param {Date} d Date object
 * @return {Date}  Date object of next month
 */
export const getNextMonthDate = (d) => {
    if (d.getMonth() === 11) {
        d.setMonth(0)
        d.setYear(d.getFullYear() + 1)
        return d
    }
    d.setMonth(d.getMonth() + 1)
    return d
}

/**
 * Format date as yyyy-mm-dd
 *
 * @param {String} date
 * @return {Date}  Date object in the yyyy-mm-dd format
 */
export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
}


/**
 * Format date as yyyy-mm-dd
 *
 * @param {String} date
 * @return {Date}  Date object in the yyyy-mm-dd format
 */
export const addSecondsToTime = (time, seconds) => {
   // Split the time string into hours and minutes
    console.log('time', time)
    const [hours, minutes] = time.split(':');
    console.log(hours, minutes)
    const totalSeconds = (hours * 3600) + (minutes * 60);
    const updatedTotalSeconds = totalSeconds + seconds;
    console.log(updatedTotalSeconds)

    // Convert the total seconds back to hours, minutes, and seconds
    const updatedHours = Math.floor(updatedTotalSeconds / 3600);
    const updatedMinutes = Math.floor((updatedTotalSeconds % 3600) / 60);

    // Format the updated hours, minutes, and seconds
    const updatedTime = `${padZero(updatedHours)}:${padZero(updatedMinutes)}`;

    return updatedTime;
}

// Helper function to pad single digits with leading zeros
function padZero(number) {
    return number.toString().padStart(2, '0');
  }

/**
 * Calculate the user's age.
 * Ref: https://stackoverflow.com/questions/4060004/calculate-age-given-the-birth-date-in-the-format-yyyymmdd
 *
 * @param {Date} birthday
 * @return {Number} Date object in the yyyy-mm-dd format
 */
export const calcAge = (birthDate) => {
    var now = new Date()

    function isLeap(year) {
        return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)
    }

    // days since the birthdate
    var days = Math.floor(
        (now.getTime() - birthDate.getTime()) / 1000 / 60 / 60 / 24
    )
    var age = 0
    // iterate the years
    for (var y = birthDate.getFullYear(); y <= now.getFullYear(); y++) {
        var daysInYear = isLeap(y) ? 366 : 365
        if (days >= daysInYear) {
            days -= daysInYear
            age++
            // increment the age only if there are available enough days for the year.
        }
    }
    return age
}

/**
 * Get tomorrow's date
 *
 * @param {Date} d today's date object
 * @return {Date}  Date object of tomorrow
 */
function getTomorrow(d) {
    d.setDate(d.getDate() + 1)
    return d
}

/**
 * Convert date string to a date object
 *
 * @param {String} s date string to be converted
 *                 Must be in the format of yyyy-mm-dd
 *                 eg. 2020-03-12
 * @return {Date}  Date object of date string 's'
 */
export const stringToDate = (s) => {
    let bits
    if (s !== undefined && typeof s === 'string') {
        bits = s.split('-')
        return new Date(bits[0], bits[1] - 1, bits[2])
    }
    return bits
}

/**
 * Generates an array of dates starting from @param from upto @param to
 * Eg. from = stringToDate("2020-01-03"), to = stringToDate("2020-06-05")
 *     function returns ["2020-01-03", "2020-01-04", ..., "2020-06-05"]
 *
 * @param {Date} from date
 * @param {Date} to date
 * @return {String[]} An array of date strings
 *                    Date string format: "yyyy-mm-dd"
 */
export const getFromToDateLabels = (from, to) => {
    let dates = [] //yyyy-mm-dd
    let date = new Date(from)
    while (date <= to) {
        dates.push(formatDate(date))
        date = getTomorrow(date)
    }
    return dates
}

/**
 * Calculate the difference between two dates
 *
 * @param {String} label of starting cycle date
 * @param {String} periodDate of next cycle date
 * @return {Number}  diffDays
 */
export const diffDays = (start, end) => {
    let date1 = new Date(start)
    let date2 = new Date(end)
    const diffTime = Math.abs(date2 - date1)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
}

/**
 * Convert 24-hour time, HH:MM:SS, to 12-hour time with AM/PM
 *
 * @param {String} time in HH:MM:SS date format
 * @return {String} time
 */
export const tConvert = (time) => {
    // Check correct time format and split into components
    time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1) // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM' // Set AM/PM
        time[0] = +time[0] % 12 || 12 // Adjust hours
    }
    return time.join('') // return adjusted time or original string
}

export const numberToMonth = {
    "1": "Jan", 
    "01": "Jan", 
    "2": "Feb",
    "02": "Feb",
    "3": "Mar",
    "03": "Mar",
    "4": "April",
    "04": "April",
    "5": "May", 
    "05": "May", 
    "6": "June", 
    "06": "June", 
    "7": "July",
    "07": "July",
    "8": "Aug", 
    "08": "Aug", 
    "9": "Sept",
    "09": "Sept",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
  }


/**
 * Convert date in seconds to the date format yyyy-mm-dd.
 *
 * @param {String} date in seconds
 * @returns {String} date in yyyy-mm-dd
 */
export const secondsToDate = (date) => {
    var d = date ? new Date(date) : new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
}

/**
 * Convert date object to a string in the format of (name of month dd, yyyy).
 * eg. May 03, 2020
 *
 * @param {Date} d  Date object
 * @return {String}  Date string in the format of (name of month dd, yyyy)
 */
export const formatTextMonthDate = (function () {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]
    function appendZero(n) {
        return n < 10 ? '0' + n : n
    }
    return function (d) {
        return `${months[d.getMonth()]} ${appendZero(
            d.getDate()
        )}, ${d.getFullYear()}`
    }
})()

/**
 * Calculate the age from @param dateString in YYYY-MM-DD format.
 *
 * @param {String} dateString
 * @returns {Number} age
 */
export const getAge = (dateString) => {
    var today = new Date()
    var birthDate = new Date(dateString)
    var age = today.getFullYear() - birthDate.getFullYear()
    var m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    return age
}


/**
 * Convert a string date , to Written Time: January 15th, 2022
 *
 * @param {String} label in YYYY-MM-DD Format
 * @return {String} time
 */

 export const stringDateConverter = (date) =>{

    const dateArr  = date.replace(/-0+/g, '-').split('-');
    const year = dateArr[0]
    const month = numberToMonth[dateArr[1]];
    const day = dateArr[2];
    const superscriptOridnal = 
          day ==="1"? "st":
          day ==="2"? "nd":
          day ==="3"? "rd":
         "th";
  
  
    return  `${month} ${day}${superscriptOridnal}, ${year}`
  
  }

  
export const chatServiceHelper = (date) => {
    const inputDate = new Date(date);

    // Get the current date and time
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - inputDate;

    // Calculate the time difference in minutes
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));

    // Calculate the time difference in hours
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

    // Calculate the time difference in days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    // Format the output based on conditions
    if (minutesDifference < 60) {
        // Less than an hour ago
        return (`${minutesDifference} min`);
    } else if (hoursDifference < 24) {
        // Less than a day ago
        return (`${hoursDifference} h`);
    } else {
        // More than a day ago
        return (`${daysDifference} day`);
    }
}

export const chatServiceMessageHelper = (date) => {

    // Convert the input date string to a Date object
    const inputDate = new Date(date);

    // Get the hours and minutes from the input date
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();

    // Format the output as "hh'h'mm"
    const formattedOutput = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

    return(formattedOutput)
}