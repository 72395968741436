import { useEffect, useReducer } from 'react'
import { Mixpanel } from '../lib/Analytics/Mixpanel'
import { isEmptyString, isObjectEqual } from '../helpers/validationHelpers'

/** Action types */
const SET_ERROR = 'SET_ERROR'
const SET_FIRST_NAME = 'SET_FIRST_NAME'
const SET_OPEN = 'SET_OPEN'

/** Reducer switch statements */
const reducer = (state, action) => {
    switch (action.type) {
        case SET_ERROR:
            return { ...state, error: action.value }
        case SET_FIRST_NAME:
            return { ...state, firstName: action.value }
        case SET_OPEN:
            return { ...state, open: action.value }
        default:
            throw new Error(
                `EditButton::reducer::error - Invalid action type: ${action.type}`
            )
    }
}

/** Return EditButton component initial state */
const initEditButton = (props) => {
    return {
        error: '',
        lastName: props.lastName,
        open: false,
    }
}

const useEditButton = (props) => {
    const [state, dispatch] = useReducer(reducer, initEditButton(props))
    let error = false

    // Set methods for each state
    const setError = (error) => dispatch({ type: SET_ERROR, value: error })
    const setFirstName = (firstName) =>
        dispatch({ type: SET_FIRST_NAME, value: firstName })
    const setOpen = (open) => dispatch({ type: SET_OPEN, value: open })

    const handleOpen = () => {
        setOpen(true)
        Mixpanel.track('Edit Account Info')
    }

    const handleFirstNameChange = (event) => setFirstName(event.target.value)

    const handleCancel = () => {
        if (props.fieldName === 'Name') {
            setFirstName(props.firstName)
        }
        setOpen(false)
    }

    const handleSave = () => {
        if (
            (props.fieldName === 'Name' &&
                props.firstName === state.firstName) ||
            (props.fieldName !== 'Name' &&
                isObjectEqual(props.value, state.value))
        ) {
            // console.log('handleSave::info - No update required.');
            setOpen(false)
            return
        }
        validateInput()
        if (error) {
            setError(
                `Please make sure that the '${props.fieldName.toLowerCase()}' field is valid and complete.`
            )
            setFirstName(props.firstName)
            error = false
            return
        }
        if (props.fieldName === 'Name') {
            props.onChange(state.firstName, 'firstName')
        }
        setOpen(false)
    }

    const handleKeyUp = (event) => {
        if (event.key === 'Enter') handleSave()
    }

    const handleErrorClose = () => setError('')

    const validateInput = () => {
        switch (props.fieldName) {
            case 'Name':
                error =
                    isEmptyString(state.firstName) ||
                    isEmptyString(state.lastName)
                break
            default:
                break
        }
    }

    useEffect(() => {
        setFirstName(props.firstName)
    }, [props])

    return {
        state,
        handleOpen,
        handleFirstNameChange,
        handleCancel,
        handleSave,
        handleKeyUp,
        handleErrorClose,
    }
}

export default useEditButton
