import React from 'react'
import PropTypes from 'prop-types'
import Alert from '@material-ui/lab/Alert'
import { Snackbar, Typography } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { PASSWORD_FORGET } from '../constants/routes'

function ErrorSnackbar(props) {
    const { error, onClose } = props

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={error !== ''}
            onClose={onClose}
        >
            <Alert variant="filled" severity="error" onClose={onClose}>
                {error ===
                'It looks like you forgot your password. Reset it here.' ? (
                    <NavLink
                        to={PASSWORD_FORGET}
                        style={{ textDecoration: 'none', color: '#fff' }}
                    >
                        <Typography variant="body1">
                            <b>{error}</b>
                        </Typography>
                    </NavLink>
                ) : (
                    <Typography variant="body1">
                        <b>{error}</b>
                    </Typography>
                )}
            </Alert>
        </Snackbar>
    )
}

ErrorSnackbar.propTypes = {
    error: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default ErrorSnackbar
