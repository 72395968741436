import React, { useState } from 'react'
import {
    IconButton,
    InputAdornment,
    TextField,
    makeStyles,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { formatDate } from '../../helpers/dateHelpers'
import { LIGHT_NAVY } from '../../constants/colors'

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
        color: theme.palette.tertiary.main,
    },
    textField: {

        '& .MuiFilledInput-root': {
            background: '#f4f4f4',
            // border: '2px solid #fff',
            borderRadius: '30px',
            paddingLeft:"10px",
            '&:hover': {
                // border: `2px solid ${LIGHT_NAVY}`,
            },
        },
        "& .MuiInputLabel-filled.MuiInputLabel":{
            marginLeft:"10px"

        },
        "& .MuiInputLabel-filled.MuiInputText":{
            marginLeft:"10px"

        },
        "& .MuiInputLabel-filled.MuiInputLabel-shrink":{
            marginLeft:"10px"

        },
        '& .MuiFilledInput-input:-webkit-autofill':{
            borderBottomLeftRadius:'inherit',
            borderBottomRightRadius:'inherit',
        }
    },
    inputWrapper:{
        disableUnderline: true, 
    }
}))

function RegistrationForm(props) {
    const classes = useStyles()
    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const showIcon = (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
            >
                {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    )

    const createTextField = (id, label, name) => {
        if (name === 'birthDate' || name === 'birth') {
            const today = formatDate(new Date())
            return (
                <TextField
                    key={id}
                    margin="normal"
                    id={id}
                    label={label}
                    name={name}
                    type="date"
                    onChange={props.onChange}
                    onKeyUp={props.onKeyUp ? props.onKeyUp : null}
                    value={props.values[name] ? props.values[name] : ''}
                    InputProps={{ inputProps: { max: today },  disableUnderline: true  }}
                    InputLabelProps={{ shrink: true, required: true }}
                    fullWidth
                    variant="filled"
                    className={classes.textField}
                />
            )
        }
        return (
            <TextField
                key={id}
                margin="normal"
                id={id}
                label={label}
                name={name}
                type={id.includes('email') ? 'email' : 'text'}
                onChange={(e) => props.onChange(e)}
                onKeyUp={props.onKeyUp ? props.onKeyUp : null}
                value={props.values[name]}
                InputProps={{ disableUnderline: true }}
                required
                fullWidth
                variant="filled"
                className={classes.textField}
            />
        )
    }

    const createPasswordField = (id, label, name) => {
        return (
            <TextField
                key={id}
                margin="normal"
                id={id}
                label={label}
                name={name}
                type={showPassword ? 'text' : 'password'}
                onChange={props.onChange}
                value={props.values[name]}
                onKeyUp={props.onKeyUp}
                InputProps={{ endAdornment: showIcon, disableUnderline: true }}
                required
                fullWidth
                variant="filled"
                className={classes.textField}
            />
        )
    }

    const fields = props.fields.map((field) => {
        let [id, label, name] = field
        if (id.includes('password')) return createPasswordField(id, label, name)
        return createTextField(id, label, name)
    })

    return <form className={classes.form}>{fields}</form>
}

export default RegistrationForm
