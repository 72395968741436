import React, { useState } from 'react'

import {
    makeStyles,
    Grid,
    Typography,

} from '@material-ui/core'

import OuraLogo from '../../assets/oura-logo.png'
import WhoopsImg from '../../assets/whoopsLogo.png'
import CachedIcon from '@mui/icons-material/Cached'
import { getMyFitnessPalData } from '../../lib/Api/MyFitnessPal/getMyFitnessPalData'
import { disconnectMyFitnessPal } from '../../lib/Api/MyFitnessPal/disconnectMyFitnessPal'
import ErrorSnackbar from '../../elements/ErrorSnackBar'
import Notification from '../../elements/Notification'



const useStyles = makeStyles((theme) => ({
    card: {
        cursor: 'pointer'
    },
    gridContainer: {
        width: '-webkit-fill-available',
    },
    divider: {
        backgroundColor: '#999999',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    logo: {
        width: '60px',
        height: '60px',
    },
    link: {
        textDecoration: 'none',
        color: '#000',
    },
    activeSince: {
        marginTop: '5%',
        fontSize: '12px',
        color: '#737373',
    },
    gridItem: {
        border: 'solid 1px #ebebeb',
        borderRadius: '12px',
        textAlign: 'center',
        padding: '8px',
        width: '180px',
        marginBottom: '10px',
    },
    dispensaryLink: {
        textAlign: 'start',
        marginBottom: '24px',
    },
    disconnect: {
        cursor: 'pointer',
        background: 'none',
        border: 'solid 1px #ff6060',
        textAlign: 'start',
        color: '#ff6060',
        padding: '10px 25px',
        borderRadius: '4px',
        '&:hover': {
            background: 'rgb(255,96,96,0.2)',
        },
    },
    inputButtonIntegrate: {
        padding: '8px 10px',
        fontSize: '0.8125rem',
        color: '#000',
        border: 'none',
        boxShadow: 'none',
        backgroundColor: '#d2d5f7',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#bfc4fd',
        },
    },
    inputButtonAccess: {
        width: '80%',
        padding: '8px 10px',
        fontSize: '0.8125rem',
        color: 'white',
        border: 'none',
        boxShadow: 'none',
        backgroundColor: '#ff6666',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#ff3232',
        },
    },
    lastSyncText: {
        fontSize: '10px'
    },
    options: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    spinRefresh: {
        animation: "$spin 3s linear infinite"

    },
    "@keyframes spin": {
        "0%": { transform: "rotate(360deg)" },
        "100%": { transform: "rotate(0deg)" }
    }

}))

const WhoopsCardContent = ({ data, id, initialData }) => {
    const classes = useStyles()


    const [updatingData, setUpdatingData] = useState(false)
    const [error, setError] = useState('')
    const [notification, setNotification] = useState('')


    const closeError = () => setError('')
    const closeNotification = () => setNotification('')

    const updateTempDropData = async () => {

        if (!updatingData) {
            setUpdatingData(true)

            try {
                const response = await getMyFitnessPalData(id)
                setNotification('Your data has been updated!')


            } catch (err) {
                setError('Server error, please try again!')
            }
            setUpdatingData(false)


        }

    }

    const handleDisconnectMFP = async () => {

        try {

            await disconnectMyFitnessPal(id)
            setNotification('MyFitnessPal disconnected!')

            initialData()

        } catch (err) {
            setError('Server error, please try again!')

        }

    }



    return (
        <Grid
            container
            direction="row"
            spacing={2}
            className={classes.card}

        >
            <Grid
                item
                container
                direction="column"
                justifyContent="center"
                xs={12}
            >
                <Grid item>
                    <img src={WhoopsImg} className={classes.logo} />
                </Grid>
                <Typography variant="body2" gutterBottom>
                    <b>Whoops</b>
                </Typography>
                <Typography variant="body2" paragraph>
                    Connect your Whoops data with flöka
                </Typography>
                {/* {data.synced === true ? <>
                    <div className={classes.options} >

                        <input
                            className={classes.inputButtonAccess}
                            type="button"
                            value={"Disconnect"}
                            onClick={handleDisconnectMFP}
                        />

                        {!data.url && <CachedIcon className={updatingData ? classes.spinRefresh : null} onClick={updateTempDropData} />}

                    </div>


                </> : null}

                {data.synced === false ? <input
                    className={classes.inputButtonIntegrate}
                    type="button"
                    value={"Integrate Now"}
                /> : null} */}
                
                <p className={classes.lastSyncText}>Available on Premium Clinic plans </p>


            </Grid>
            <ErrorSnackbar error={error} onClose={closeError} />
            <Notification text={notification} onClose={closeNotification} />
        </Grid>
    )
}

export default WhoopsCardContent