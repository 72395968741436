

import axios from 'axios';
import { baseUrl } from '../Api/config';
const { projectAuth } = require('../Auth/firebase_config')

const getOuraHeartRateData = (patientId, fromDate, toDate) =>{

    return new Promise((resolve, reject)=>{
        projectAuth.currentUser
           .getIdToken(true)
           .then(function(idToken){
             const reqBody = {
                headers: {
                     Authorization: `${idToken}`,
                },
            };
            const params={
                patientID:patientId,
                from_Date:fromDate,
                to_Date:toDate
            }
            axios.post(`${baseUrl}/oura/heart_rate_chart`, params, reqBody)
                .then(res =>{
                    resolve(res.data);
                })
                .catch((err)=>{ 
                    console.log('get_oura_sleep_data:err:: -', err)
                })
 
           })
           .catch((error)=>{
             reject(error.message || error)
         })
     })
}

export default getOuraHeartRateData