import { get_patient_data, getPatientDataNoPractitioner } from '.'
import Practitioner from '../../../classes/Practitioner'
import { database } from '../../Auth/firebase_config'
import { decrypt, getAge, decryptPatient } from '../../Patient/index'
import { get_practitioner } from '../Practitioner/index'

/**
 * Remove tracking Obj from Firebase in @param patientId.
 *
 * @param {String[]} patientId - Patient's Firebase ids
 * @return {Promise<any>}
 */

const removeTracking = (patientId, courseId, flag)=>{
    return new Promise(async (resolve, reject) => {
        try{
            const trackingRef = database.ref(`/users/${patientId}/tracking`);
            trackingRef.once(
                'value', (snapshot) => {
                    const data = snapshot.val();
                    console.log('tracking data', data)
                    data[courseId].isConsented = true;
                    if(flag === 'connect'){
                        data[courseId].isConnected = true;
                    }
                    console.log('data', data)
                    trackingRef.set(data)
                })
            resolve('Remove success')
        }
        catch(err){
            reject(err)
        }

    })

}

export default removeTracking