/**
 * @description This file contains all string manipulation helper functions.
 */

/**
 * Convert the first character of @param string into uppercase.
 *
 * @param {String} string
 * @return A new string with uppercase first character and the rest of the string unchange.
 */
export const firstLetterUpper = (string = '', el) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Convert the first character of @param string into uppercase.
 *
 * @param {String} string
 * @return A new string with uppercase first character and the rest of the string unchange.
 */
 export const onlyFirstLetterUpper = (string="", el) => {
    let strArr = string.split(" ");
   return strArr.map(s =>s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()).join(" ");
}


/**
 * Convert the rest of the string into lowercase except for the first character.
 *
 * @param {String} string
 * @return {String} A new string with all characters in lowercase except for the first character.
 */
export const exceptFirstLower = (string = '') => {
    return string.replace(/\S*/g, function (word) {
        return word.charAt(0) + word.slice(1).toLowerCase()
    })
}

/**
 * Convert two words to camelCase
 *
 * @param {String[]} words of two strings in an array
 * @return {String} result
 */
export const camelCase = (words) => {
    let splitWords = words.split(' ')
    const first = splitWords[0]
    const second = firstLetterUpper(splitWords[1])
    const result = first + second
    return result
}

/**
 * Capitalize first letter of each word
 *
 * @param  {String} str
 * @return {String} splitStr
 */
export function titleCase(str) {
    if (!str) return
    var splitStr = str.toLowerCase().split(' ')
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(' ')
}

/**
 * Truncate file name and add ellipsis in the middle of string.
 *
 * @param {String} str
 * @returns {String} Truncated string with ellipsis in the middle
 */
export const truncateFilename = (str) => {
    if (!str) return ''
    // Find first 3 characters of filename
    var start = str.substring(0, 10)

    /* Replace everything before the final '.' with an ellipsis.
     * The final '.' is retained and therefore only two additional '.' are needed.
     */
    var end = str.replace(/^.*(?=\.[^.]+$)/g, '..')

    /* Append resulting extention to the first 3 characters of
     * the file name that we found earlier.
     */
    return start + end
}
