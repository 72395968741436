import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Unsubscribe from a practitioner with @param practitionerId
 *
 * @param {String} practitionerId
 * @param {String} patientId
 * @return {Promise<any>}
 */
const removePractitioner = (practitionerId, patientId) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                }
                const params = { patientId }

                axios
                    .post(
                        `${baseUrl}/adminPatient/practitioner/${practitionerId}/disconnect`,
                        params,
                        reqBody
                    )
                    .then((res) => resolve(res))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export default removePractitioner
