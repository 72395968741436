

import axios from 'axios';
import { baseUrl } from '../Api/config';
const { projectAuth } = require('../Auth/firebase_config')

const updateBBTUnit = (patientId, unit) =>{

    return new Promise((resolve, reject)=>{
        projectAuth.currentUser
           .getIdToken(true)
           .then(function(idToken){
             const reqBody = {
                headers: {
                     Authorization: `${idToken}`,
                },
            };

            const params = {
                unit
            }

            axios.post(`${baseUrl}/patient/${patientId}/setting/bbtUnit/update`, params, reqBody)
                    .then(res =>{
                        resolve(res.data);
                    })
                    .catch((err)=>{ 
                        console.log('update_bbtUnit_setting:err:: -', err)
                    })
 
           })
           .catch((error)=>{
             reject(error.message || error)
         })
     })
}

export default updateBBTUnit