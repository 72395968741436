import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

// save user's survey answers as draft

const completeSurvey =(patientId, practitionerId, params) =>{
    return new Promise(async (resolve, reject) => {
        projectAuth.currentUser
        .getIdToken(true)
            .then(function (idToken) { 
                const reqBody = { headers: { Authorization: `${idToken}` } }

                axios.put(
                    `${baseUrl}/patient/${patientId}/intakeForms/completeForm/${practitionerId}`, 
                    params,
                    reqBody
                )
                .then((res) => resolve(res.data))
                .catch((err) => reject(JSON.stringify(err)))

                
            })
            .catch((error) => reject(error))

    })
}

export default completeSurvey