import React , { useState, useContext } from 'react'
import PatientsContext from '../../contexts/PatientsContext'
import { 
    Grid, 
    Box, 
    makeStyles, 
    Button, 
    Typography, 
    Dialog, 
    DialogActions, 
    DialogContentText, 
    DialogContent, 
    DialogTitle, 
    Slide, 
    IconButton } from '@material-ui/core'
import { remove_invite_notification } from '../../lib/Api/Patient';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({

    img:{
       width:'40px'
    },
    modalTitle: {
        textAlign: 'center',
        padding:"25px 10px 25px 10px",
        margin: '10px 0px 15px 0px',
        backgroundColor:"#f9f6ff",
        borderRadius:'20px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        gap:'20px',
        [theme.breakpoints.down(600)]: {
            flexDirection:'column',
        }
    },
    dialogTitle:{
        width:'100%'
    },
    box:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.down(500)]: {
            justifyContent:'center'
        },

    },
    dialogContent:{
       padding:'0px 80px 0px 80px',
       marginBottom:'25px',
       marginTop:"20px",
       [theme.breakpoints.down(600)]: {
           padding:'0px 20px 0px 20px',
       }

    },
    modalBox: {
        '& .MuiPaper-root': {
            width: '80%',
            maxWidth: '800px',
            borderRadius: '30px',
            padding: '10px 30px',
        }
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding:'0px 80px 0px 80px',
        marginBottom: '5%',
        gap: '83px',
        [theme.breakpoints.down('900')]: {
            flexDirection: 'column',
            gap: '20px'
        },

    },
    leftButton: {
        border:'1px solid #000',
        width:'220px',
        backgroundColor: '#fff',
        color:"#000",
        borderRadius: '20px',
        padding: '5px 20px 5px 20x',
        '&:hover': {
            backgroundColor: '#fff',
        },

    },
    rightButton: {
        width:'220px',
        backgroundColor: '#000',
        color: '#fff',
        borderRadius: '20px',
        padding: '5px 20px 5px 20px',
        '&:hover': {
            backgroundColor: '#000',
        },
        "&:disabled": {
            backgroundColor: '#eeeeee',
        }
    },
    input:{
        width:'100%',
        borderRadius:'20px',
        border:'0.5px solid #b6b6b6',
        marginBottom:'20px',
        height:'40px',
        padding:'10px',
        "&:focus": {
            outline:"none"
        },
    },
    dialogContent:{
        padding:"20px 50px 40px 50px",
        textAlign:"center",
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        gap:'30px',
        fontSize:'18px',
    },
    rejectSection:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        gap:"30px",

    },
    message:{
        fontWeight:'bold',
    }



}))



const IncomingInvite = ({  open, onClose, data, user }) => {
    
 
    const classes = useStyles()
    console.log('data', data)
    const coachUnique = Object.keys(data)[0]
    const { name } = data[Object.keys(data)[0]]
    console.log('user', user)
    const isAccepted = data[Object.keys(data)[0]].accepted
    const isDeclined = data[Object.keys(data)[0]].declined


    const remove =() =>{
        remove_invite_notification(user.uid, coachUnique)
        .then(res=>{
            console.log('res', res)
        })
    }



    return (
        <Dialog
            open={open}
            onClose={()=>{
                onClose()
                remove()

            }}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            className={classes.modalBox}
        >
            <Typography variant="h4" paragraph className={classes.modalTitle}>
                {isAccepted &&`Your Coach Has Accepted Your Invite`}
                {isDeclined && `Your Coach Has Declined Your Invite`}
            </Typography>
            <DialogContent className={classes.dialogContent}>

               {isAccepted && <Typography variant="h6">Congratulations!</Typography>}

               {
                isAccepted &&
               <div>
                    You are now connected with {name}. This means you will be able to share your health history and 
                    data with this health expert. They will also be able to send over recommendations and provide insights on your 
                    evolving health journey!
                </div>
                }

                {
                isDeclined &&
               <div className={classes.rejectSection}>
                    <div>{name} has declined your request to connect. here's her message: </div>
                    <div className={classes.message}>
                    {
                        `"${data?.[Object.keys(data)[0]]?.message}"`
                    }
                    </div>
                </div>
                }
               
               
        
            </DialogContent>
        </Dialog>
    )


}

export default IncomingInvite