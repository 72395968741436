/** Practitioner Class */

export default class Practitioner {
    constructor(pracDbModel) {
        if (typeof pracDbModel !== 'undefined') {
            // console.log('pracDbModel', pracDbModel);
            // Construct new Practitioner Object with pracDbModel
            this.address = pracDbModel.address
            this.avatar = pracDbModel.avatar
            this.professionalBio = pracDbModel.professional_bio
            this.certifications = pracDbModel.certifications
            this.clinicName = pracDbModel.clinic_name
            this.clinicDescription = pracDbModel.clinic_description
            this.clinicLogo = pracDbModel.clinic_logo
            this.email = pracDbModel.email
            this.firstName = pracDbModel.first_name
            this.geolocation = pracDbModel.geolocation
            this.hours = pracDbModel.hours
            this.lastName = pracDbModel.last_name
            this.phoneNumber = pracDbModel.phone_number
            this.promoCode = pracDbModel.promo_code || ''
            this.qbId = pracDbModel.qb_id
            this.stripeId = pracDbModel.stripe_id
            this.servedRegions = pracDbModel.served_regions
            this.specialInterests = pracDbModel.special_interests
            this.websiteLink = pracDbModel.website_link
        } else {
            // Construct new Practitioner Object using default values
            this.address = ''
            this.avatar = ''
            this.professionalBio = ''
            this.certifications = []
            this.clinicName = ''
            this.clinicDescription = ''
            this.clinicLogo = ''
            this.email = ''
            this.firstName = ''
            this.geolocation = { lat: 0, lng: 0 }
            this.hours = ''
            this.lastName = ''
            this.phoneNumber = ''
            this.promoCode = ''
            this.qbId = 0
            this.stripeId = ''
            this.servedRegions = []
            this.specialInterests = []
            this.websiteLink = ''
        }
    }
}
