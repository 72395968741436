import axios from 'axios';
import { baseUrl } from '../Api/config';
const { projectAuth } = require('../Auth/firebase_config')

/**
 * Update bbt temperature with @param key through backend routes.
 *
 * @param {Object} params - Object includes temperature number
 * @param {String} patientId
 * @return {Promise} - response data
 */


// dynmoDB route: `${baseUrl}/usersPatient/${patientId}/nutrition/meals/new`
// firebase route: `${baseUrl}/patient/${patientId}/mealEntry/update`

const _addNutritionData  =(params, patientId)=>{

    return new Promise((resolve, reject)=>{
        projectAuth.currentUser
            .getIdToken(true)
            .then((idToken)=>{
                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                };
                axios.post(`${baseUrl}/meals/${patientId}/create`, params, reqBody)
                    .then(res =>{
                        resolve(res);
                    })
                    .catch((err)=>{ 
                        console.log('add_nutrition::err:: -', err)
                    })

            }).catch((error)=>{
                reject(error.message || error)
            })
    })

}


export default _addNutritionData;