import React, { useState, useEffect } from 'react'

import {
    Card,
    CardActionArea,
    Grid,
    CardContent,
    makeStyles,
    Box,
    CircularProgress
} from '@material-ui/core'

import ErrorSnackbar from '../../elements/ErrorSnackBar'
import Notification from '../../elements/Notification'


// import useFullscript from '../../hooks/useFullscript'


import { getUrlToken } from '../../lib/Api/TempDrop/getUrl-Token'

import TdContent from './TdContent'
// import FsPractitionerTable from './FsPractitionerTable'
// import FsPatientTable from './FsPatientTable'
// import FullscriptSigninDialog from '../Dialog/FullscriptSigninDialog'

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        width: '-webkit-fill-available',
        '&.MuiGrid-container': {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
        },
        height: '100%'
    },
    cardContainer: {
        textAlign: 'center',
        width: '250px',
        height: '250px',
    },
    openCardContainer: {
        textAlign: 'center',
        width: '250px',
        height: '250px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cardContent: {
        display: 'flex',
        justifyContent: 'center',
        cursor: 'default',
    },
    cardActionArea: {
        height: '100%',
        padding: '26px',
    },
    focusHighlight: {
        '&:hover $focusHighlight': {
            opacity: 0,
        },
    },
}))



function TempDropIntegration({ user }) {
    const classes = useStyles()
    const [error, setError] = useState('')
    const [notification, setNotification] = useState('')
    const [tempDropInfo, setTempDropInfo] = useState({})
    const [loading, setLoading] = useState(true)


    const closeError = () => setError('')
    const closeNotification = () => setNotification('')


    useEffect(() => {
        initialTempDrop()
    }, [null])


    const initialTempDrop = async () => {
        try {
            setLoading(true)
            const response = await getUrlToken(user.uid)
            setTempDropInfo(response)
            setLoading(false)
        } catch (err) {
            setError('Error connecting to the server, please refresh.')
        }
    }

    const clickTempDropHandler = () => {
        if(tempDropInfo.url){
            window.location.href = tempDropInfo.url
        } 
    }

    return (
        <Box className={classes.box}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                className={classes.gridContainer}
                onClick={loading ? undefined : clickTempDropHandler}
            >
                <Card
                    className={
                        loading
                            ? classes.openCardContainer
                            : classes.cardContainer
                    }
                >
                    {loading ? <CircularProgress color="secondary"/> : null}
                  
                        {!loading ? <CardActionArea
                            // onClick={handleClickOpen}
                            className={classes.cardActionArea}
                            
                        >
                            <CardContent className={classes.cardContent}>
                                <TdContent data={tempDropInfo} id={user.uid} initialTempDrop={initialTempDrop} />
                            </CardContent>
                        </CardActionArea> : null}
                     
                </Card>
            </Grid>

            
            <ErrorSnackbar error={error} onClose={closeError} />
            <Notification text={notification} onClose={closeNotification} />
        </Box>
    )
}

export default TempDropIntegration