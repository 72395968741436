import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Get patient's firebase data, profile image, subscription information and insights data.
 *
 * @return {Promise<any>}
 */
const getPatientData = (patientId, practitionerId) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                // console.log(idToken)
                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                }

                const data = { practitionerId }

                axios
                    .post(`${baseUrl}/patient/${patientId}`, data, reqBody)
                    .then((res) => {
                        console.log('res',res)
                        let formatedData = Object.values(res.data)[0];
                        resolve(formatedData)
                    })
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export default getPatientData