import CryptoJS from 'crypto-js'

const key = process.env.REACT_APP_DECRYPTION_KEY

/**
 * Decrypt hashed string retrieved from Firebase
 * Use: first name and age decryption in patient profile
 *
 * @param {String} message hash string
 * @return {String} Decrypted string
 */
function decrypt(msg) {
    if (!msg) return undefined
    const decrypted = CryptoJS.AES.decrypt(msg, key)
    // console.log('result', decrypted.toString(CryptoJS.enc.Utf8));
    return decrypted.toString(CryptoJS.enc.Utf8)
}

export default decrypt
