/**
 * @description This file contains all general helper functions that
 *              do not fit into other helper categories.
 */

/**
 * Add index attribute to each object element within the array @param arr .
 *
 * @param {Object[]} arr
 * @return {Object[]} new object array with extra attribute 'index' indicating the element's position
 *                    in @param arr
 */
export const populateIndex = (arr) => {
    return arr.map((r, i) => {
        return { ...r, index: i }
    })
}

/**
 * Return an array with length @param length filled with @param value .
 *
 * @param {Number} length
 * @param {Any} value
 * @return {Any[]}
 */
export const prefillArray = (length, value) => {
    let result
    ;(result = []).length = length
    result.fill(value)
    return result
}

/**
 * Transform @param arr into a new array wih length @param arr.length/@param groupSize
 * where each group is an element of the new array and
 * each group is an array of length @param groupSize .
 *
 * @param {Any[]} arr - Array to be transformed
 * @param {Number} groupSize
 * @return {Any[]} A new array where elements are array with length @param groupSize
 */
export const groupArrayItems = (arr, groupSize) => {
    if (!arr) return []
    let newArray = []
    while (arr.length) {
        newArray.push(arr.splice(0, groupSize))
    }
    return newArray
}


/**
 * Truncate string to have a maximum length of @param maxLength and
 * append ellipsis to the end of the string.
 *
 * @param {Number} maxLength
 * @param {String} string
 * @return {String} @param string if string has length < maxLength,
 *                  else truncated string with length = maxLength, excluding the ellipsis
 */
 export const addEllipsis = (maxLength, string) => {
    if (!string) return
    if (string.length <= maxLength) return string
    return string.substring(0, maxLength) + ` ...`
}

/**
 * combine two array of equal length into an object 
 * where the first array is the key and the second array is the value
 *
 * @param {Number} Array1
 * @param {String} Array2
 * @return {String} @param object 
 */
export const combineArrayObj = (arr1, arr2) => {
    if (!arr1 || !arr2) return
    if (arr1.length !== arr2.length) return
    let obj = {}
    arr1.forEach((key, i) => obj[key] = arr2[i])
    return obj
}