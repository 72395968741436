import React, { useContext, useEffect, useState } from 'react'

import { Grid, Typography, makeStyles, Box, Divider } from '@material-ui/core'

import ErrorSnackbar from '../../elements/ErrorSnackBar'
import InfoField from '../../elements/InfoField'
import Notification from '../../elements/Notification'
import UserAvatar from '../../elements/UserAvatar'
import LoadingSpinner from '../../components/utils/LoadingSpinnerCSS'
import IntegrateButton from '../../elements/buttons/IntegrateButton'
import EditButton from '../../elements/buttons/EditButton'

import GoogleFitLogo from '../../assets/google-fit-logo.png'
import FitbitLogo from '../../assets/fitbit-logo.svg'

import PatientsContext from '../../contexts/PatientsContext'
import ChangePasswordDialog from '../Dialog/ChangePasswordDialog'

import { get_googlefit_token } from '../../lib/Api/GoogleFit'
import { get_fitbit_token } from '../../lib/Api/Fitbit'
import { encrypt_basicInfo } from '../../lib/Api/Patient'
import { exchangeToken } from '../../lib/Api/TempDrop/exchangeToken'
import { getTempDropData } from '../../lib/Api/TempDrop/getTempDropData'
import { useHistory } from 'react-router-dom'




const TempDrop = ({ user, refreshAppData }) => {

    let history = useHistory()

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(true)
    const [notification, setNotification] = useState('')

    const closeError = () => setError('')
    const closeNotification = () => setNotification('')

    useEffect(() => {
        console.log('here')
        initialFunction()
    }, [])

    const initialFunction = async () => {
        const queryParams = new URLSearchParams(window.location.search)
        const code = queryParams.get("code")
        const error = queryParams.get("error")

        setLoading(true)
        try {

            if (code) {
                //New user is giving access to the data
                await exchangeToken(code, user.uid)
                await getTempDropData(user.uid)
                setNotification(
                    'TempDrop Integration succesfully!'
                )
                
                
                refreshAppData()


            } else if (error) {
                //If an error comes up
                const error_description = queryParams.get("error_description")
                throw error_description

            } else {
                //This is an user with storedToken and authorization to access data.
                // Load the tempdrop data 

                
                history.push('/integrations')


            }

            setLoading(false)
        } catch (err) {
            //Handle errors

            setError(
                err
            )
            history.push('/integrations')
        }

    }

   

    return (<>

       <LoadingSpinner text="Loading data..." />
        <ErrorSnackbar error={error} onClose={closeError} />
        <Notification text={notification} onClose={closeNotification} />
    </>)
}

export default TempDrop