import React, { useEffect, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import makeStyles from '@material-ui/styles/makeStyles'

/**
 * Display the original image in shape circle
 * Use in Account page: Profile picture, Clinic logo
 *
 * @param   {object} props
 * props: {
 *      {string}            src
        {string}            alt
        {string}            dimension
		{string}			height
		{string}			width
		{string}			border
 * }
 * @return  {component}  A circle Avatar that display image with address props.src
*/

function UserAvatar(props) {
    const dimension = props.dimension ? props.dimension : '10vw'
    const margin = props.margin ? props.margin : '1rem'
    const width = props.width ? props.width : '15vw'
    const height = props.height ? props.height : '15vw'
    const border = props.border ? props.border : 'unset'
    const position = props.position ? props.position : 'unset'
    const useStyles = makeStyles((theme) => ({
        root: {
            height: dimension,
            width: dimension,
            objectFit: 'cover',
            margin: margin,
            border: border,
            position: position,
            [theme.breakpoints.down(500)]: {
                marginTop:"50px"
            },
            [theme.breakpoints.down('sm')]: {
                height: height,
                width: width,
            },
        },
    }))

    const classes = useStyles()
    const [src, setSrc] = useState(props.src)

    useEffect(() => setSrc(props.src), [props.src])

    return (
        <Avatar
            className={classes.root}
            variant="circular"
            src={src}
            alt={props.alt}
        >
            <Avatar
                className={classes.root}
                variant="circular"
                src={process.env.REACT_APP_DEFAULT_AVATAR}
                alt="default-avatar"
            />
        </Avatar>
    )
}

export default UserAvatar
