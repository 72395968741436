import React, { useContext, useEffect, useState } from 'react'

import { Grid, Typography, makeStyles, Box, Divider, Card, CircularProgress, CardActionArea, CardContent } from '@material-ui/core'

import ErrorSnackbar from '../../elements/ErrorSnackBar'
import InfoField from '../../elements/InfoField'
import Notification from '../../elements/Notification'
import UserAvatar from '../../elements/UserAvatar'
import LoadingSpinner from '../../components/utils/LoadingSpinnerCSS'
import IntegrateButton from '../../elements/buttons/IntegrateButton'
import EditButton from '../../elements/buttons/EditButton'

import GoogleFitLogo from '../../assets/google-fit-logo.png'
import FitbitLogo from '../../assets/fitbit-logo.svg'

import PatientsContext from '../../contexts/PatientsContext'
import ChangePasswordDialog from '../Dialog/ChangePasswordDialog'

import { get_googlefit_token } from '../../lib/Api/GoogleFit'
import { get_fitbit_token, exchange_code, sync_fitbit } from '../../lib/Api/Fitbit'
import { encrypt_basicInfo } from '../../lib/Api/Patient'
import { fi } from 'date-fns/locale'

const useStyles = makeStyles((theme) => ({
    googlefitIcon: {
        width: '12rem',
    },
    fitbitLogo: {
        width: '9rem',
        imageRendering: '-webkit-optimize-contrast',
        transform: 'translateZ(0)',
        margin: '25px',
    },
    integration: {
        border: 'solid 1px #EEEEEE',
        padding: '16px',
        borderRadius: '4px',
        height: '100%',
    },
    gridContainer: {
        width: '-webkit-fill-available',
        '&.MuiGrid-container': {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
        },
        height: '100%'
    },
    cardContainer: {
        textAlign: 'center',
        width: '250px',
        height: '250px',
    },
    openCardContainer: {
        textAlign: 'center',
        width: '250px',
        height: '250px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        cursor: 'default',
        alignItems: 'center'
    },
    cardActionArea: {
        height: '100%',
        padding: '26px',
        display: 'flex',
        flexDirection: 'column'
    },
    focusHighlight: {
        '&:hover $focusHighlight': {
            opacity: 0,
        },
    },
    inputButtonIntegrate: {
        padding: '8px 10px',
        fontSize: '0.8125rem',
        color: '#000',
        border: 'none',
        boxShadow: 'none',
        backgroundColor: '#d2d5f7',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#bfc4fd',
        },
        width: '80%'
    },
    spinRefresh: {
        animation: "$spin 3s linear infinite"

    },
    "@keyframes spin": {
        "0%": { transform: "rotate(360deg)" },
        "100%": { transform: "rotate(0deg)" }
    },
    fitbitDisconnect: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        gap: "15px",
    }
}))

function FitbitIntegration({
    handleSyncFitbit,
    handleRevokeFitbit
}) {
    const user = useContext(PatientsContext)
    const classes = useStyles()
    const [error, setError] = useState('')
    const [notification, setNotification] = useState('')
    const [fitbitAuthUrl, setFitbitAuthUrl] = useState('')
    const [syncingFitbit, setSyncingFitbit] = useState(false)
    const [updatingData, setUpdatingData] = useState(false)
    const [loading, setLoading] = useState(true)

    // Fitbit setup
    // let hash = window.location.hash
    // var fitbitAuthResult = hash.split('&').reduce(function (res, item) {
    //     var parts = item.split('=')
    //     res[parts[0]] = parts[1]
    //     return res
    // }, {})
    // const fitbitAccessToken = fitbitAuthResult['#access_token']
    // const fitbitTokenExpiredAt = fitbitAuthResult['expires_in']
    // const fitbitStateToken = fitbitAuthResult['state']
    // const fitbitScopes = fitbitAuthResult['scope']


    const updateFitbitData = async () => {

        if (!updatingData) {
            setUpdatingData(true)

            try {
                const response = await sync_fitbit(undefined, user?.uid, undefined)
                setNotification('Your data has been updated!')


            } catch (err) {
                setError('Server error, please try again!')
            }
            setUpdatingData(false)


        }

    }


    const initialFitbitFunction = async () => {
        setLoading(true)
        console.log('--------------------------------------------------')
        console.log('first2')

        // const queryParams = new URLSearchParams(window.location.search)
        // const fitbitAccessToken = queryParams.get("code")
        // console.log(fitbitAccessToken)

        console.log(user?.data?.fitbitToken?.refresh_token)
        console.log(user?.fitbitAuth)


        console.log('here else')

        const queryParams = new URLSearchParams(window.location.search)
        const fitbitAccessToken = queryParams.get("code")

        if (fitbitAccessToken) {
            console.log('here fitbittoken detected')
            console.log('here fitbittoken detected')
            console.log('here fitbittoken detected')

            await exchange_code(fitbitAccessToken, user.uid)
                .then(() => {
                    console.log('DONE EXCHANGE CODE')
                    // Define the path you want to navigate to
                    const newPath = '/integrations';
                    // Navigate to the new path, causing a page refresh
                    window.location.href = window.location.origin + newPath;

                })

        } else if (user?.data?.fitbitToken?.refresh_token) {

            sync_fitbit(undefined, user.uid, undefined)

        } else {

            console.log('trigger3')
            await get_fitbit_token(user.uid)
                .then((url) => {
                    console.log(url)
                    setFitbitAuthUrl(url)
                })
                .catch((err) => console.log('signin_fitbit::err -', err))

        }



        setLoading(false)
    }


    useEffect(() => {

        initialFitbitFunction()

    }, [])



    const closeError = () => setError('')

    const closeNotification = () => setNotification('')



    return (
        <>
            <Box className={classes.box}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    className={classes.gridContainer}
                // onClick={loading ? undefined : clickTempDropHandler}
                >

                    <Card className={classes.openCardContainer}>
                        {loading || !user.stripeId || syncingFitbit ?

                            <CircularProgress color="secondary" />
                            : <CardActionArea
                                // onClick={handleClickOpen}
                                className={classes.cardActionArea}

                            >
                                <CardContent className={classes.cardContent}>
                                    <>
                                        <img className={classes.fitbitLogo} src={FitbitLogo} />
                                        <Typography variant="body2" paragraph>
                                            Connect your Fitbit data with Floka
                                        </Typography>

                                            <IntegrateButton
                                                type="fitbit"
                                                isSyncing={syncingFitbit}
                                                user={user}
                                                url={fitbitAuthUrl}
                                                onRevoke={handleRevokeFitbit}
                                                updatingDataLoadeer={updatingData}
                                                updateFitbitData={updateFitbitData}
                                            />


                                    </>
                                </CardContent>
                            </CardActionArea>}
                    </Card>



                </Grid>



            </Box>



            <ErrorSnackbar error={error} onClose={closeError} />
            <Notification text={notification} onClose={closeNotification} />

        </>
    )
}

export default FitbitIntegration
