import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Get patient's nutrition data from AWS DynamoDB.
 *
 * @params {String} patientId
 * @return {Promise<any>}
 */
const getSignedUrl = (documentURL) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
        .getIdToken(true)
            .then(function (idToken) { 
                const reqBody = { headers: { Authorization: `${idToken}` } }
            
                const params = { documentURL }

                axios.put(
                    `${baseUrl}/patient/getSecureFile`, 
                    params,
                    reqBody
                )
                .then((res) => resolve(res.data))
                .catch((err) => reject(JSON.stringify(err)))

                
            })
            .catch((error) => reject(error))
    })
}

export default getSignedUrl