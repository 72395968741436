import React, { useEffect, useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    makeStyles,
} from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import {
    BLACK,
    TRANSLUCENT_WARNING_ORANGE,
    WARNING_ORANGE,
} from '../../constants/colors'
import ErrorSnackbar from '../../elements/ErrorSnackBar'
import { changePassword } from '../../lib/Auth/index'
import { Mixpanel } from '../../lib/Analytics/Mixpanel'
import RegistrationForm from '../../elements/forms/RegistrationForm'
import { encrypt_basicInfo } from '../../lib/Api/Patient'
import SignInGoogleBtn from '../../assets/btn-google-signin-light-normal.svg'
import { getAge } from '../../helpers/dateHelpers'

const useStyles = makeStyles((theme) => ({
    button: {
        borderColor: WARNING_ORANGE,
        color: WARNING_ORANGE,
        backgroundColor: TRANSLUCENT_WARNING_ORANGE,
        '&:hover': {
            backgroundColor: WARNING_ORANGE,
            color: BLACK,
        },
    },
    dialog: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: '5rem',
        alignSelf: 'center',
    },
    successDialog: {
        padding: '0 2rem 1rem 2rem',
    },
    signinGoogle: {
        backgroundImage: `url(${SignInGoogleBtn})`,
        backgroundSize: '100% 100%',
        width: '140px',
        height: '40px',
    },
}))

let changePasswordFields = [
    ['old-password', 'Old Password', 'oldPassword'],
    ['new-password', 'New Password', 'newPassword'],
    ['confirm-new-password', 'Confirm New Password', 'confirmNewPassword'],
]

const basicInfoFields = [
    ['birth-date', 'Birth Date', 'birth'],
    ['firstName', 'First Name', 'name'],
]

const basicInfoAgeField = [['birth-date', 'Birth Date', 'birth']]

function ChangePasswordDialog(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [error, setError] = useState('')
    const [resetSuccess, setResetSuccess] = useState(false)
    const [credentials, setCredentials] = useState({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    })
    const [basicInfo, setBasicInfo] = useState({
        birth: '',
        name: '',
    })

    useEffect(() => {
        if (localStorage.getItem('isFirstLogin') === 'true') {
            setOpen(true)
        } else if (
            (props.user.firstName &&
                Number.isInteger(props.user.age) &&
                props.user.age < 13) ||
            props.user.firstName === 'None'
        ) {
            setResetSuccess(true)
            setOpen(true)
            setBasicInfo({ birth: '' })
        }
    }, [])

    const handleCredentialsChange = (event) => {
        setCredentials({
            ...credentials,
            [event.target.name]: event.target.value,
        })
    }

    const handleBasicInfoChange = (event) => {
        setBasicInfo({ ...basicInfo, [event.target.name]: event.target.value })
    }

    const handleCloseError = () => setError('')

    const handleClickOpen = () => setOpen(true)

    const handleClose = () => setOpen(false)

    const savePassword = (e) => {
        e.preventDefault()
        if (
            !credentials.oldPassword ||
            !credentials.newPassword ||
            !credentials.confirmNewPassword
        ) {
            setError('Please fill in the required fields.')
            return
        }
        if (credentials.oldPassword === credentials.newPassword) {
            setError('Your new password is same as your old password.')
            return
        }
        if (credentials.newPassword !== credentials.confirmNewPassword) {
            setError('Passwords do not match.')
            return
        }
        changePassword(credentials.oldPassword, credentials.newPassword)
            .then((res) => {
                Mixpanel.track('Change Password')
                setResetSuccess(true)
                if (localStorage.getItem('isFirstLogin') === 'false')
                    setOpen(false)
                if (typeof props.handleResetSuccess === 'function') {
                    props.handleResetSuccess()
                    return
                }
            })
            .catch((err) => setError(JSON.stringify(err)))
    }

    const saveBasicInfo = () => {
        if (
            (basicInfo.hasOwnProperty('name') && !basicInfo.name) ||
            (basicInfo.hasOwnProperty('birth') && !basicInfo.birth)
        ) {
            setError('Please fill in the required fields.')
            return
        }
        const date = basicInfo.birth.split('-')
        const formatBirth = date[0] + '-' + date[1] + '-' + date[2]
        const age = getAge(formatBirth)

        if (age < 13) {
            setError(
                'In order to register for a Flöka account you must be at least 13 years of age.'
            )
            return
        }

        const newBasicInfo = { ...basicInfo }
        newBasicInfo.birth = date[1] + '-' + date[2] + '-' + date[0]

        const attributes = Object.keys(basicInfo)
        const values = Object.values(basicInfo)
        const promises = attributes.map((attr, i) =>
            encrypt_basicInfo(values[i], attr, props.user.uid)
        )

        Promise.all(promises)
            .then(([...data]) => {
                Mixpanel.track('Update basic information')
                setOpen(false)
                setBasicInfo({ name: '', birth: '' })
                window.location.reload()
            })
            .catch((err) =>
                console.log('saveBasicInfo::encrypt_basicInfo::error - ', err)
            )
    }

    const handleKeyUp = (event) => {
        if (event.key === 'Enter') savePassword(event)
    }

    if (resetSuccess) {
        return (
            <>
                <Dialog open={open} onClose={handleClose}>
                    {props.user.firstName === 'None' &&
                    props.user.age === 'Not available' ? (
                        <DialogContent>
                            <Typography variant="h6" align="center" paragraph>
                                <b>
                                    Please enter your birth date and first name.
                                </b>
                            </Typography>
                            <RegistrationForm
                                fields={basicInfoFields}
                                onChange={handleBasicInfoChange}
                                values={basicInfo}
                                light="true"
                            />
                            <DialogActions
                                className={classes.dialog}
                                style={{ marginBottom: '1rem' }}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={handleClose}
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    variant="outlined"
                                    autoFocus
                                    onClick={saveBasicInfo}
                                    className={classes.button}
                                >
                                    CONFIRM
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    ) : props.user.firstName &&
                      Number.isInteger(props.user.age) &&
                      props.user.age < 13 ? (
                        <DialogContent>
                            <Typography variant="h6" align="center" paragraph>
                                <b>Please enter your birth date.</b>
                            </Typography>
                            <RegistrationForm
                                fields={basicInfoAgeField}
                                onChange={handleBasicInfoChange}
                                values={basicInfo}
                                light="true"
                            />
                            <DialogActions
                                className={classes.dialog}
                                style={{ marginBottom: '1rem' }}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={handleClose}
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    variant="outlined"
                                    autoFocus
                                    onClick={saveBasicInfo}
                                    className={classes.button}
                                >
                                    CONFIRM
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    ) : (
                        <>
                            <DialogTitle className={classes.dialog}>
                                <CheckCircleOutlineIcon
                                    className={classes.icon}
                                />
                            </DialogTitle>
                            <DialogContent className={classes.successDialog}>
                                <Typography
                                    variant="h5"
                                    align="center"
                                    paragraph
                                >
                                    <b>
                                        You can now login with your new
                                        password!
                                    </b>
                                </Typography>
                            </DialogContent>
                        </>
                    )}
                </Dialog>
                <ErrorSnackbar error={error} onClose={handleCloseError} />
            </>
        )
    }

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                onClick={handleClickOpen}
                className={classes.button}
            >
                Change Password
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <React.Fragment>
                    <DialogContent>
                        <Typography variant="body1" paragraph>
                            Please note that when changing your password, we ask
                            you to set yourself a secure password that is at
                            least 8 characters long, contains both uppercase and
                            lowercase letters as well as numbers and special
                            characters.
                        </Typography>
                        <RegistrationForm
                            fields={changePasswordFields}
                            onChange={handleCredentialsChange}
                            onKeyUp={handleKeyUp}
                            values={credentials}
                            light="true"
                        />
                    </DialogContent>
                    <DialogActions
                        className={classes.dialog}
                        style={{ marginBottom: '1rem' }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            autoFocus
                            onClick={savePassword}
                            className={classes.button}
                        >
                            Change
                        </Button>
                        {/* <Button className={classes.signinGoogle}></Button> */}
                    </DialogActions>
                </React.Fragment>
            </Dialog>
            <ErrorSnackbar error={error} onClose={handleCloseError} />
        </React.Fragment>
    )
}

//   return (
//     <React.Fragment>
//       <Button variant="outlined" onClick={handleClickOpen} className={classes.button}>
//         <b>CHANGE PASSWORD</b>
//       </Button>
//       <Dialog open={open} onClose={handleClose}>
// 				{ localStorage.getItem('isFirstLogin') === 'true' ? (
// 						<DialogContent>
// 							<Typography variant="h6" align="center" paragraph>
// 								<b>Please enter your birth date and first name.</b>
// 							</Typography>
// 							<RegistrationForm fields={basicInfoFields} onChange={handleBasicInfoChange} values={basicInfo} light="true" />
// 							<DialogActions className={classes.dialog} style={{ marginBottom: '1rem' }}>
// 								<Button variant="outlined" onClick={handleClose}>
// 									CANCEL
// 											</Button>
// 								<Button variant="outlined" autoFocus onClick={saveBasicInfo(props.user)} className={classes.button}>
// 									CONFIRM
// 											</Button>
// 							</DialogActions>
// 						</DialogContent>
// 					) : (
//             <React.Fragment>
//               <DialogContent>
//                 <Typography variant="h6" paragraph>
//                   Please note that when changing your password, we ask you to set yourself a secure password
//                   that is at least 8 characters long, contains both uppercase and lowercase letters
//                   as well as numbers and special characters.
//                             </Typography>
//                 <RegistrationForm fields={changePasswordFields} onChange={handleCredentialsChange} onKeyUp={handleKeyUp} values={credentials} light="true" />
//               </DialogContent>
//               <DialogActions className={classes.dialog} style={{ marginBottom: '1rem' }}>
//                 <Button variant="outlined" onClick={handleClose}>
//                   CANCEL
//                             </Button>
//                 <Button variant="outlined" autoFocus onClick={savePassword} className={classes.button}>
//                   CHANGE
//                             </Button>
//               </DialogActions>
//             </React.Fragment>
//           )}
//       </Dialog>
//       <ErrorSnackbar error={error} onClose={handleCloseError} />
//     </React.Fragment>
//   )
// }

export default ChangePasswordDialog
