import { projectAuth } from './firebase_config'
import { Mixpanel } from '../Analytics/Mixpanel'

/**
 * Signout current login patient
 */
const signout = () => {
    projectAuth.signOut()
    Mixpanel.reset() // Stop event tracking for this user after logout
}

export default signout
