import axios from 'axios'
import { baseStripeUrl } from '../config'

/**
 * Retrieve stripe customer details.
 *
 * @param {String} email
 * @return {Promise<any>}
 */
const retrieveAccount = (email) => {
    return new Promise((resolve, reject) => {
        const params = { email: email }

        axios
            .post(`${baseStripeUrl}/retrieve_users`, params)
            .then((res) => resolve(res.data))
            .catch((err) => reject(JSON.stringify(err)))
    })
}

export default retrieveAccount
