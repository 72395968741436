
const axios = require('axios');

const sendSlackNotification = async (channel, message) => {
  const slackToken = '98o0rNos7KoCftkkjwAPmQ0a'; // Replace with your Slack app token
  const apiUrl = 'https://slack.com/api/chat.postMessage';

  try {
    const response = await axios.post(apiUrl, {
      channel: channel,
      text: message,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${slackToken}`,
      },
    });

    console.log('Slack notification sent:', response.data);
  } catch (error) {
    console.error('Error sending Slack notification:', error.message);
  }

};


export default sendSlackNotification;