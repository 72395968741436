import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Mark a schedule item as complete or missed in Program-Schedules table in dynamoDB.
 *
 * @params {Obj} data
 * @return {Promise<any>}
 */
const updateSchedule = (data) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                const reqBody = { headers: { Authorization: `${idToken}` } }
                const params = {
                    scheduleIndex: data.scheduleIndex,
                    value: data.value,
                    commentIndex: data.commentIndex,
                    practitioner: data?.practitioner
                }
            
                axios
                    .put(
                        `${baseUrl}/adminPatient/schedules/${data.programId}/update`,
                        params,
                        reqBody
                    )
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((error) => reject(error))
    })
}

export default updateSchedule
