import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {  makeStyles, IconButton, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Box } from '@material-ui/core'
import clsx from  'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { get_practitioner } from "../../lib/Api/Practitioner";
import { remove_tracking, connect_practitioner, disconnect_practitioner } from "../../lib/Api/Patient";
import { remove_practitioner } from "../../lib/Api/Practitioner";

const useStyles = makeStyles((theme) => ({
    modalBox: {
        '& .MuiPaper-root': {
            width: '100%',
            display:'block',
            overflow:'scroll',
            maxWidth: '1000px',
            borderRadius: '10px',
            [theme.breakpoints.down(500)]: {
                height:"100%"
            },
        }
    },
    box:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.down(500)]: {
            justifyContent:'center'
        },

    },
    headerText:{
        [theme.breakpoints.down(500)]: {
            width:"90%"
        },
    },
    modalTitle: {
        gap:"10px",
        color:"#000",
        textAlign: 'center',
        margin: '20px 0px 15px 0px',
        display:'flex',
        flexDirection:"column",
        justifyContent:'center',
        alignItems:"center",
        
    },
    darkDescription: {
        textAlign: 'center',
        fontSize: '15px',
        marginBottom: '4px',
        fontWeight:"normal",
        width:"50%",
        [theme.breakpoints.down('600')]: {
            width:"80%",
        },
    },
    button: {
        width: '350px',
        backgroundColor:"#000",
        color:"#fff",
        borderRadius: '5px',
        textAlign: 'center',
        padding: '10px 10px',
        [theme.breakpoints.down(500)]: {
            padding:"7px 7px",
            width:"90%"
        },
    },
    removeButton:{
        width: '350px',
        backgroundColor:"#de0000",
        color:"#fff",
        borderRadius: '5px',
        textAlign: 'center',
        padding: '10px 10px',
        [theme.breakpoints.down(500)]: {
            padding:"7px 7px",
            width:"90%"
        },

    },
    
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems:'start',
        marginBottom: '5%',
        gap: '83px',
        [theme.breakpoints.down(900)]: {
            flexDirection:'column',
            alignItems:"center",
            gap: '20px'

        },

    },
    buttonGroup:{
        display:"flex",
        flexDirection:"column",
        alignItems:'center',
        gap:"12px"

    },
    buttonTextBelow:{
        fontSize:"12px",
        width:"300px",
        textAlign:'center',
        color:"#6e6f71",
        [theme.breakpoints.down(500)]: {
            width:"80%",
        },
    },
    directConnectBuutton:{
        border:'1.5px solid #000',
        backgroundColor:"#fff",
        color:"#000",
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
    directConnectBuuttonWithPractitioner:{
        height:'70px',
        border:'1.5px solid #000',
        backgroundColor:"#fff",
        color:"#000",
        '&:hover': {
            backgroundColor: '#fff',
        },

    },

    img:{
        width:"30px"
    },
    dialogContent:{
        display:'flex',
        flexDirection:"column",
        alignItems:'center',
        marginTop:"30px",
        marginBottom:"50px",
        [theme.breakpoints.down(900)]: {
            marginTop:"30px",
            marginBottom:"30px",
        },
        [theme.breakpoints.down(500)]: {
            marginTop:"15px",
            marginBottom:"15px",
        },
    },
    highlight:{
        fontWeight:'bold'
    }


}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Tracking = ({ patientId, practitionerId, practitionerName, open, onClose, tracking }) =>{

    const classes = useStyles();

    const courseIds = Object.keys(tracking).find(id=>tracking[id].isConsented===false)

    const removeFlag = async (patientId, courseIds, flag)=>{
        let res = await remove_tracking(patientId, courseIds, flag);
        console.log('res', res)
        onClose()
    }

    const connect = async()=>{
        let params ={ 
            patientId,
            practitionerId: tracking[courseIds]?.practitionerid,
            courseId:courseIds
        }
        let res = await connect_practitioner(params)
        console.log("res",res)
        removeFlag(patientId, courseIds, 'connect')
        window.location.reload(); 

    }

    const disconnect = async () =>{

        //disconnect first 
        let params ={
            patientId,
            practitionerId: practitionerId
        }
        let res =await disconnect_practitioner(params)
        console.log("res", res)

        //then connect
        let params1 ={ 
            patientId,
            practitionerId: tracking[courseIds]?.practitionerid,
            courseId: courseIds
        }
        let res1 = await connect_practitioner(params1)
        console.log("res1", res1);
        removeFlag(patientId, courseIds, 'connect')
        window.location.reload()



    }


    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                className={classes.modalBox}
            >
                <DialogTitle className={classes.dialogTitle}>
                   <Box className={classes.box} >
                        <Box>
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                   </Box>
                </DialogTitle>
              <Typography variant="h5" paragraph className={classes.modalTitle}>
                <span className={classes.headerText}>Oops!</span>
                <span className={classes.headerText}>
                    Please comfirm if you want to connect with following coach(s)?
                </span>

                <span  className={classes.headerText}>{tracking[courseIds]?.practitionerName}</span>
              </Typography>
              <DialogContent className={classes.dialogContent}>         
                    <div className={classes.darkDescription}>
                        The course you purchased: <span className={classes.highlight}>{tracking[courseIds]?.courseName}</span> which contains a 1:1 package. 
                        Included in this package is the ability to share your health data 
                        and tracking with your coach. This way your coach can get a full 360 
                        degree view of your health and provide next steps/recommendations accordingly.
                        Would you like to share your data with your coach?
                    </div>
                </DialogContent>

                <DialogActions className={classes.buttonsContainer}>
                    <div className={classes.buttonGroup}>
                        <Button 
                            variant='contained' 
                            onClick={()=>{
                                removeFlag(patientId, courseIds, 'consent')
                            }}
                            className={
                                clsx(classes.button, classes.directConnectBuutton)
                            }
                        >
                            Continue without flöka connection
                        </Button>
                        <div className={classes.buttonTextBelow}>
                            You will still be able to book 
                            1:1s with this coach
                            independent of flöka
                        </div>
                    </div>
                    <div className={classes.buttonGroup}>
                        {
                        practitionerId?
                        <Button 
                            variant='contained' 
                            onClick={disconnect} 
                            className={classes.removeButton}
                        >
                           { 
                                `I want to disconnect with my current coach ${practitionerName}, 
                                and connect and share data with ${tracking[courseIds]?.practitionerName}`
                            }
                        </Button> 
                            :
                        <Button 
                            variant='contained' 
                            onClick={connect} 
                            className={classes.button}
                        >
                            Yes, Connect and Share my Data with {tracking[courseIds]?.practitionerName}
                        </Button>
                         }
                        <div className={classes.buttonTextBelow}>
                            I understand that by connecting I consent to be share
                            any wellness data I have inputted on the flöka apps or synced to 
                            Flöka, including but not limited to the categories of sleep, 
                            cycle, fitness, mind, body and nutrition.
                        </div>
                    </div>
                </DialogActions>

           </Dialog>
        </>

    )

}

export default Tracking