import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Sync Google Fit data to Firebase and AWS with currently signed in user with @param patId.
 *
 * @param {String} patientId patient ID
 * @param {Object} invite
 * @return {Promise<any>}
 */
const syncData = (patId, code) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                }
                const params = {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    patientId: patId,
                    code: code,
                }

                axios
                    .post(`${baseUrl}/googleFit/data`, params, reqBody)
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export default syncData
