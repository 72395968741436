import { projectAuth, auth } from './firebase_config'

/**
 *  Re-authenticate patient and reset password with @param newPass.
 */
const changePassword = (oldPass, newPass) => {
    return new Promise((resolve, reject) => {
        const user = projectAuth.currentUser
        const credential = auth.EmailAuthProvider.credential(
            user.email,
            oldPass
        )
        user.reauthenticateWithCredential(credential)
            .then(function () {
                user.updatePassword(newPass)
                    .then(function () {
                        resolve('re-authenticated')
                    })
                    .catch(function (error) {
                        reject(error.message)
                    })
            })
            .catch(function (error) {
                reject(error.message)
            })
    })
}

export default changePassword
