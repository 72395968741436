
import axios from 'axios';
import { baseUrl } from '../Api/config';
const { projectAuth } = require('../Auth/firebase_config')

/**
 * Update a flow entry with @param key through backend routes.
 *
 * @param {Object} params - new cycle data 
 * @param {String} patientId
 * @return {Promise} - response data
 */

const _updateFbCycleEntry =(params, patientId)=>{

    return new Promise((resolve, reject)=>{
       projectAuth.currentUser
          .getIdToken(true)
          .then(function(idToken){
            const reqBody = {
                headers: {
                    Authorization: `${idToken}`,
                },
            };
                axios.post(`${baseUrl}/patient/${patientId}/cycle/addEntry`, params, reqBody)
                    .then(res =>{
                        resolve(res);
                    })
                    .catch((err)=>{ 
                        console.log('update_cycle_entry::err:: -', err)
                    })

          })
          .catch((error)=>{
            reject(error.message || error)
        })
    })

}

export default _updateFbCycleEntry


// Original functions that directly interact with firebase:

// function _updateFbCycleEntry(key, newKey, data, newData, patientId) {
//     return new Promise((resolve, reject) => {
//         try {
//             var updates = {}
//             if (key !== newKey) {
//                 updates['/users/' + patientId + '/cycle/flow/' + newKey] =
//                     newData
//                 updates['/users/' + patientId + '/cycle/flow/' + key] = data
//                 resolve(database.ref().update(updates))
//             } else {
//                 updates['/users/' + patientId + '/cycle/flow/' + newKey] =
//                     newData
//                 resolve(database.ref().update(updates))
//             }
//         } catch (err) {
//             reject(err.message || JSON.stringify(err))
//         }
//     })
// }