import React, { useState, useEffect, useCallback } from 'react'
import * as ReactDOM from 'react-dom';

import {
    Card,
    CardActionArea,
    Grid,
    CardContent,
    makeStyles,
    Box,
    CircularProgress
} from '@material-ui/core'

import ErrorSnackbar from '../../elements/ErrorSnackBar'
import Notification from '../../elements/Notification'
import MyFitnessContentVital from './MyFitnessContent_Vital'

import { getMyFitnessUrl } from '../../lib/Api/MyFitnessPal/getMyFitnessUrl'
import { getLinkToken } from '../../lib/Api/MyFitnessPal/getLinkToken'
import { getMyFitnessPalData } from '../../lib/Api/MyFitnessPal/getMyFitnessPalData'
import { useVitalLink } from "@tryvital/vital-link";


const useStyles = makeStyles((theme) => ({
    gridContainer: {
        width: '-webkit-fill-available',
        '&.MuiGrid-container': {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
        },
        height: '100%'
    },
    cardContainer: {
        textAlign: 'center',
        width: '250px',
        height: '250px',
    },
    openCardContainer: {
        textAlign: 'center',
        width: '250px',
        height: '250px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cardContent: {
        display: 'flex',
        justifyContent: 'center',
        cursor: 'default',
    },
    cardActionArea: {
        height: '100%',
        padding: '26px',
    },
    focusHighlight: {
        '&:hover $focusHighlight': {
            opacity: 0,
        },
    },
}))

const MyFitnessPalIntegrationVital = ({ user }) => {

    const classes = useStyles()
    // const [error, setError] = useState('')
    const [notification, setNotification] = useState('')
    const [myFitnessPalInfo, setMyFitnessPalInfo] = useState({})
    const [loading, setLoading] = useState(false)

    // const closeError = () => setError('')
    const closeNotification = () => setNotification('')



    useEffect(() => {
        initialMyFitnessPal()
    }, [])


    const initialMyFitnessPal = async () => {
        try {
            setLoading(true)
            const response = await getMyFitnessUrl(user.uid)
            console.log('my fitness Pal response', response)
            setMyFitnessPalInfo(response)

            // if (response.synced === true) {
            //     await getMyFitnessPalData(user.uid)
            // }


            setLoading(false)
        } catch (err) {
            // setError('Error connecting to the server, please refresh.')
        }
    }

    const onSuccess = useCallback(async (metadata) => {
        // Device is now connected.
        setLoading(true)
        await getMyFitnessPalData(user.uid)
        setNotification('MyFitnessPal has been synced!')
        setLoading(false)
        window.location.reload()

    }, []);

    const onExit = useCallback((metadata) => {
        // User has quit the link flow.
        console.log("onExit", metadata);
        initialMyFitnessPal()
    }, []);

    const onError = useCallback((metadata) => {
        // Error encountered in connecting device.
        console.log("onError", metadata);
    }, []);

    const config = {
        onSuccess,
        onExit,
        onError,
        env: "sandbox",
        region: "us",
    };



    const { open, ready, error } = useVitalLink(config);

    useEffect(() => {
        console.log('------error')
        console.log(error)
    }, [error])

    const clickHandler = async () => {
        console.log('lets start')
        if (myFitnessPalInfo.synced === false) {
            console.log(' this is not synced, lets start')
            const response = await getLinkToken(user.uid)
            console.log('got token', response)

            if (response.linkToken !== null) {
                open(response.linkToken)
            } else {
                setNotification('Please refresh the page and try again.')


            }
        }
    }

    return (
        <>
            <Box className={classes.box}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    className={classes.gridContainer}
                    onClick={loading ? undefined : clickHandler}
                >
                    <Card
                        className={
                            loading ? classes.openCardContainer : classes.cardContainer
                        }
                    >
                        {loading ? <CircularProgress color="secondary" /> : null}
                        {!loading ? <CardActionArea
                            // onClick={handleClickOpen}
                            className={classes.cardActionArea}

                        >
                            <CardContent className={classes.cardContent}>
                                <MyFitnessContentVital
                                    data={myFitnessPalInfo}
                                    id={user.uid}
                                    initialData={() => onSuccess()}
                                />
                            </CardContent>
                        </CardActionArea> : null}

                    </Card>
                </Grid>

                {/* <ErrorSnackbar error={error} onClose={closeError} /> */}
                <Notification text={notification} onClose={closeNotification} />
            </Box>
        </>
    )
}

export default MyFitnessPalIntegrationVital