import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'

/**
 * Get notifications from a practitioner from dynamoDB.
 *
 * @return {Promise<any>}
 */
const getNotifications = (setting, patId) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then((idToken)=> { 
                const reqBody = { headers: { Authorization: `${idToken}` } }
                const params = {
                    setting
                }
                axios
                    .post(`${baseUrl}/patient/${patId}/notifications/update`, params, reqBody)
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch((err) =>
                reject(`getAuthSession::error - ${JSON.stringify(err)}`)
            )
    })
}

export default getNotifications